export const categories  = [{
    id: 3,
    label: "Lifestyle",
    sub_categories: [{
        id: 1,
        label: "Fashion and apparel"
    }, {
        id: 3,
        label: "Childcare"
    }, {
        id: 4,
        label: "Beauty and cosmetics"
    }, {
        id: 9,
        label: "Jewelry and luxury products"
    }, {
        id: 16,
        label: "Other lifestyle"
    }, {
        id: 17,
        label: "Lifestyle - other"
    }, {
        id: 28,
        label: "Weddings"
    }, {
        id: 32,
        label: "Gifts and flowers"
    }, {
        id: 129,
        label: "Tobacco"
    }]
}, {
    id: 43,
    label: "Sports",
    sub_categories: [{
        id: 7,
        label: "Other sports"
    }, {
        id: 8,
        label: "Sports - other"
    }, {
        id: 33,
        label: "Hunting and shooting"
    }, {
        id: 39,
        label: "Martial arts"
    }, {
        id: 40,
        label: "Water sports"
    }, {
        id: 43,
        label: "Cycling and biking"
    }, {
        id: 57,
        label: "Golf"
    }, {
        id: 60,
        label: "Fishing"
    }, {
        id: 68,
        label: "Fantasy sports"
    }, {
        id: 74,
        label: "Winter sports"
    }, {
        id: 77,
        label: "Running"
    }, {
        id: 78,
        label: "Basketball"
    }, {
        id: 99,
        label: "Boxing"
    }, {
        id: 116,
        label: "Baseball"
    }, {
        id: 122,
        label: "Soccer"
    }, {
        id: 131,
        label: "Tennis"
    }, {
        id: 140,
        label: "American football"
    }, {
        id: 143,
        label: "Climbing"
    }]
}, {
    id: 11,
    label: "Product/service",
    sub_categories: []
}, {
    id: 22,
    label: "Clothing (brand)",
    sub_categories: []
}, {
    id: 52,
    label: "Food and drink",
    sub_categories: [{
        id: 11,
        label: "Other food and drink"
    }, {
        id: 12,
        label: "Food and drink - other"
    }, {
        id: 15,
        label: "Groceries"
    }, {
        id: 26,
        label: "Restaurants and delivery"
    }, {
        id: 36,
        label: "Beverages"
    }, {
        id: 105,
        label: "Cooking and recipes"
    }, {
        id: 121,
        label: "Vegetarian and vegan"
    }]
}, {
    id: 69,
    label: "Home and garden",
    sub_categories: [{
        id: 18,
        label: "Other home and garden"
    }, {
        id: 19,
        label: "Home and garden - other"
    }, {
        id: 27,
        label: "Gardening"
    }, {
        id: 50,
        label: "Furniture"
    }, {
        id: 62,
        label: "Home improvement and maintenance"
    }, {
        id: 130,
        label: "Interior design"
    }]
}, {
    id: 20,
    label: "Health/beauty",
    sub_categories: []
}, {
    id: 56,
    label: "Health",
    sub_categories: [{
        id: 13,
        label: "Other health"
    }, {
        id: 14,
        label: "Health - other"
    }, {
        id: 22,
        label: "Nutrition diets and fitness"
    }, {
        id: 48,
        label: "Womens health"
    }, {
        id: 53,
        label: "Health conditions and concerns"
    }, {
        id: 59,
        label: "Alternative and natural medicine"
    }, {
        id: 85,
        label: "Dentist and dental services"
    }, {
        id: 98,
        label: "Mental health"
    }, {
        id: 106,
        label: "Childrens health"
    }, {
        id: 109,
        label: "Pharmacy"
    }, {
        id: 145,
        label: "Biotechnology and pharmaceuticals"
    }, {
        id: 146,
        label: "Developmental and physical disabilities"
    }]
}, {
    id: 31,
    label: "Shopping & retail",
    sub_categories: []
}, {
    id: 70,
    label: "Ecommerce & shopping",
    sub_categories: [{
        id: 20,
        label: "Other ecommerce and shopping"
    }, {
        id: 21,
        label: "Ecommerce and shopping - other"
    }, {
        id: 41,
        label: "Marketplace"
    }, {
        id: 110,
        label: "Coupons and rebates"
    }, {
        id: 120,
        label: "Auctions"
    }, {
        id: 124,
        label: "Classifieds"
    }]
}, {
    id: 101,
    label: "Computers electronics and technology",
    sub_categories: [{
        id: 23,
        label: "Consumer electronics"
    }, {
        id: 29,
        label: "Graphics multimedia and web design"
    }, {
        id: 52,
        label: "Telecommunications"
    }, {
        id: 58,
        label: "Programming and developer software"
    }, {
        id: 61,
        label: "Email"
    }, {
        id: 63,
        label: "Computer hardware"
    }, {
        id: 80,
        label: "Other computers electronics and technology"
    }, {
        id: 81,
        label: "Computers electronics and technology - other(in united states"
    }, {
        id: 86,
        label: "Computers electronics and technology - other"
    }, {
        id: 92,
        label: "Social media networks"
    }, {
        id: 128,
        label: "Other computers electronics and technology(in bosnia and herz"
    }, {
        id: 150,
        label: "Advertising networks"
    }, {
        id: 154,
        label: "Web hosting and domain names"
    }]
}, {
    id: 41,
    label: "Pets and animals",
    sub_categories: [{
        id: 6,
        label: "Pet food and supplies"
    }, {
        id: 30,
        label: "Other pets and animals"
    }, {
        id: 31,
        label: "Pets and animals - other"
    }, {
        id: 54,
        label: "Animals"
    }, {
        id: 75,
        label: "Pets"
    }, {
        id: 103,
        label: "Fish and aquaria"
    }, {
        id: 108,
        label: "Horses"
    }, {
        id: 125,
        label: "Birds"
    }]
}, {
    id: 118,
    label: "Business and consumer services",
    sub_categories: [{
        id: 24,
        label: "Digital marketing"
    }, {
        id: 25,
        label: "Marketing and advertising"
    }, {
        id: 38,
        label: "Business services"
    }, {
        id: 89,
        label: "Other business and consumer services"
    }, {
        id: 90,
        label: "Business and consumer services - other"
    }, {
        id: 91,
        label: "Textiles"
    }, {
        id: 102,
        label: "Printing & self publishing"
    }, {
        id: 112,
        label: "Real estate"
    }, {
        id: 114,
        label: "Shipping and logistics"
    }]
}, {
    id: 9,
    label: "E-commerce website",
    sub_categories: []
}, {
    id: 58,
    label: "Retail company",
    sub_categories: []
}, {
    id: 113,
    label: "Shopping & fashion",
    sub_categories: []
}, {
    id: 156,
    label: "Home decor",
    sub_categories: []
}, {
    id: 6,
    label: "Vehicles",
    sub_categories: [{
        id: 2,
        label: "Motorcycles"
    }, {
        id: 10,
        label: "Automotive industry"
    }, {
        id: 37,
        label: "Motorsports"
    }, {
        id: 45,
        label: "Other vehicles"
    }, {
        id: 46,
        label: "Vehicles - other"
    }, {
        id: 72,
        label: "Makes and models"
    }, {
        id: 73,
        label: "Aviation"
    }, {
        id: 117,
        label: "Boats"
    }]
}, {
    id: 2,
    label: "Jewelry/watches",
    sub_categories: []
}, {
    id: 5,
    label: "Brand",
    sub_categories: []
}, {
    id: 262,
    label: "Arts & entertainment",
    sub_categories: [{
        id: 44,
        label: "Streaming & online tv"
    }, {
        id: 69,
        label: "Other arts and entertainment"
    }, {
        id: 70,
        label: "Arts and entertainment - other"
    }, {
        id: 83,
        label: "Music"
    }, {
        id: 88,
        label: "Visual arts and design"
    }, {
        id: 101,
        label: "Humor"
    }, {
        id: 139,
        label: "Animation and comics"
    }, {
        id: 144,
        label: "Books and literature"
    }]
}, {
    id: 66,
    label: "Beauty, cosmetic & personal care",
    sub_categories: []
}, {
    id: 21,
    label: "Games",
    sub_categories: [{
        id: 5,
        label: "Video games consoles and accessories"
    }, {
        id: 49,
        label: "Games - other"
    }, {
        id: 51,
        label: "Other games"
    }, {
        id: 79,
        label: "Board and card games"
    }, {
        id: 87,
        label: "Puzzles and brainteasers"
    }, {
        id: 111,
        label: "Roleplaying games"
    }]
}, {
    id: 8,
    label: "Interest",
    sub_categories: []
}, {
    id: 7,
    label: "Baby goods/kids goods",
    sub_categories: []
}, {
    id: 273,
    label: "Outdoor & sporting goods company",
    sub_categories: []
}, {
    id: 254,
    label: "Hobbies and leisure",
    sub_categories: [{
        id: 42,
        label: "Photography"
    }, {
        id: 104,
        label: "Crafts"
    }, {
        id: 107,
        label: "Antiques and collectibles"
    }, {
        id: 118,
        label: "Camping scouting and outdoors"
    }, {
        id: 123,
        label: "Ancestry and genealogy"
    }, {
        id: 132,
        label: "Models"
    }]
}, {
    id: 61,
    label: "Local business",
    sub_categories: []
}, {
    id: 39,
    label: "Pet supplies",
    sub_categories: []
}, {
    id: 32,
    label: "Clothing store",
    sub_categories: []
}, {
    id: 174,
    label: "Food & beverage",
    sub_categories: []
}, {
    id: 157,
    label: "Electronics",
    sub_categories: []
}, {
    id: 27,
    label: "Beauty & well-being",
    sub_categories: []
}, {
    id: 14,
    label: "Women's clothing store",
    sub_categories: []
}, {
    id: 28,
    label: "Vitamins/supplements",
    sub_categories: []
}, {
    id: 53,
    label: "Health & wellness website",
    sub_categories: []
}, {
    id: 110,
    label: "Footwear store",
    sub_categories: []
}, {
    id: 260,
    label: "Kitchen/cooking",
    sub_categories: []
}, {
    id: 23,
    label: "Software company",
    sub_categories: []
}, {
    id: 121,
    label: "Website",
    sub_categories: []
}, {
    id: 51,
    label: "Bags/luggage",
    sub_categories: []
}, {
    id: 123,
    label: "Home & garden",
    sub_categories: []
}, {
    id: 85,
    label: "Design & fashion",
    sub_categories: []
}, {
    id: 302,
    label: "Sports & recreation",
    sub_categories: []
}, {
    id: 186,
    label: "Gift shop",
    sub_categories: []
}, {
    id: 102,
    label: "Food & beverage company",
    sub_categories: []
}, {
    id: 475,
    label: "Community and society",
    sub_categories: [{
        id: 65,
        label: "Faith and beliefs"
    }, {
        id: 84,
        label: "Lgbtq"
    }, {
        id: 94,
        label: "Other community and society"
    }, {
        id: 95,
        label: "Community and society - other"
    }, {
        id: 127,
        label: "Holidays and seasonal events"
    }, {
        id: 135,
        label: "Philanthropy"
    }, {
        id: 149,
        label: "Dating and relationships"
    }]
}, {
    id: 481,
    label: "Artist",
    sub_categories: []
}, {
    id: 477,
    label: "Games/toys",
    sub_categories: []
}, {
    id: 75,
    label: "News & media publishers(in united states)",
    sub_categories: []
}, {
    id: 227,
    label: "Apparel & clothing",
    sub_categories: []
}, {
    id: 177,
    label: "Arts & crafts store",
    sub_categories: []
}, {
    id: 42,
    label: "Sporting goods store",
    sub_categories: []
}, {
    id: 353,
    label: "Finance",
    sub_categories: [{
        id: 55,
        label: "Other finance"
    }, {
        id: 56,
        label: "Finance - other"
    }, {
        id: 64,
        label: "Financial planning and management"
    }, {
        id: 66,
        label: "Banking credit and lending"
    }, {
        id: 93,
        label: "Accounting and auditing"
    }, {
        id: 119,
        label: "Investing"
    }]
}, {
    id: 137,
    label: "Accessories",
    sub_categories: []
}, {
    id: 194,
    label: "Electronics & technology",
    sub_categories: []
}, {
    id: 76,
    label: "Cosmetics store",
    sub_categories: []
}, {
    id: 19,
    label: "Furniture store",
    sub_categories: []
}, {
    id: 45,
    label: "Company",
    sub_categories: []
}, {
    id: 1,
    label: "Community",
    sub_categories: []
}, {
    id: 74,
    label: "News & media publishers(in usa)",
    sub_categories: []
}, {
    id: 143,
    label: "Furniture",
    sub_categories: []
}, {
    id: 112,
    label: "Media/news company",
    sub_categories: []
}, {
    id: 298,
    label: "Sports",
    sub_categories: []
}, {
    id: 205,
    label: "Pet store",
    sub_categories: []
}, {
    id: 513,
    label: "Gambling",
    sub_categories: [{
        id: 76,
        label: "Casinos"
    }, {
        id: 82,
        label: "Lottery"
    }, {
        id: 96,
        label: "Other gambling"
    }, {
        id: 97,
        label: "Gambling - other"
    }, {
        id: 133,
        label: "Sports betting"
    }]
}, {
    id: 122,
    label: "Jewelry & watches store",
    sub_categories: []
}, {
    id: 188,
    label: "Tools/equipment",
    sub_categories: []
}, {
    id: 297,
    label: "Events & entertainment",
    sub_categories: []
}, {
    id: 673,
    label: "Bicycle shop",
    sub_categories: []
}, {
    id: 126,
    label: "Sunglasses & eyewear store",
    sub_categories: []
}, {
    id: 151,
    label: "Food, beverages & tobacco",
    sub_categories: []
}, {
    id: 99,
    label: "Health & medical",
    sub_categories: []
}, {
    id: 270,
    label: "Vehicles & transportation",
    sub_categories: []
}, {
    id: 18,
    label: "Fashion and apparel",
    sub_categories: []
}, {
    id: 310,
    label: "Men's clothing store",
    sub_categories: []
}, {
    id: 100,
    label: "Camera/photo",
    sub_categories: []
}, {
    id: 108,
    label: "Household supplies",
    sub_categories: []
}, {
    id: 65,
    label: "Medical & health",
    sub_categories: []
}, {
    id: 508,
    label: "Outdoor equipment store",
    sub_categories: []
}, {
    id: 158,
    label: "Boutique store",
    sub_categories: []
}, {
    id: 679,
    label: "Musical instrument store",
    sub_categories: []
}, {
    id: 140,
    label: "Internet company",
    sub_categories: []
}, {
    id: 16,
    label: "Nonprofit organization",
    sub_categories: []
}, {
    id: 492,
    label: "Heavy industry and engineering",
    sub_categories: [{
        id: 71,
        label: "Construction and maintenance"
    }, {
        id: 137,
        label: "Chemical industry"
    }, {
        id: 142,
        label: "Energy industry"
    }, {
        id: 147,
        label: "Other heavy industry and engineering"
    }, {
        id: 151,
        label: "Metals and mining"
    }, {
        id: 152,
        label: "Agriculture"
    }]
}, {
    id: 144,
    label: "Electronics store",
    sub_categories: []
}, {
    id: 94,
    label: "Skin care service",
    sub_categories: []
}, {
    id: 170,
    label: "Baby & children's clothing store",
    sub_categories: []
}, {
    id: 253,
    label: "Beauty product supplier",
    sub_categories: []
}, {
    id: 111,
    label: "News & media website",
    sub_categories: []
}, {
    id: 132,
    label: "Coffee shop",
    sub_categories: []
}, {
    id: 172,
    label: "Home improvement",
    sub_categories: []
}, {
    id: 147,
    label: "Patio/garden",
    sub_categories: []
}, {
    id: 181,
    label: "Wine/spirits",
    sub_categories: []
}, {
    id: 421,
    label: "Toy store",
    sub_categories: []
}, {
    id: 183,
    label: "Business service",
    sub_categories: []
}, {
    id: 198,
    label: "Health and beauty shop",
    sub_categories: []
}, {
    id: 84,
    label: "Beauty and cosmetics",
    sub_categories: []
}, {
    id: 219,
    label: "Automotive parts store",
    sub_categories: []
}, {
    id: 639,
    label: "Science and education",
    sub_categories: [{
        id: 100,
        label: "Education"
    }, {
        id: 126,
        label: "Environmental science"
    }, {
        id: 134,
        label: "Biology"
    }, {
        id: 141,
        label: "Libraries and museums"
    }, {
        id: 153,
        label: "History"
    }]
}, {
    id: 79,
    label: "Software",
    sub_categories: []
}, {
    id: 201,
    label: "Fashion accessories store",
    sub_categories: []
}, {
    id: 54,
    label: "Activewear store",
    sub_categories: []
}, {
    id: 106,
    label: "Education",
    sub_categories: []
}, {
    id: 485,
    label: "Sportswear store",
    sub_categories: []
}, {
    id: 135,
    label: "Home & garden website",
    sub_categories: []
}, {
    id: 412,
    label: "Decoration and interior design store",
    sub_categories: []
}, {
    id: 402,
    label: "Clothing company",
    sub_categories: []
}, {
    id: 47,
    label: "Jewelry store",
    sub_categories: []
}, {
    id: 90,
    label: "Adult(in united states)",
    sub_categories: []
}, {
    id: 218,
    label: "Food delivery service",
    sub_categories: []
}, {
    id: 304,
    label: "Business services",
    sub_categories: []
}, {
    id: 314,
    label: "Art",
    sub_categories: []
}, {
    id: 272,
    label: "Arts & entertainment",
    sub_categories: []
}, {
    id: 701,
    label: "Fishing store",
    sub_categories: []
}, {
    id: 82,
    label: "Financial service",
    sub_categories: []
}, {
    id: 180,
    label: "Appliances",
    sub_categories: []
}, {
    id: 308,
    label: "Cell phone accessory store",
    sub_categories: []
}, {
    id: 506,
    label: "Baby store",
    sub_categories: []
}, {
    id: 322,
    label: "Health food store",
    sub_categories: []
}, {
    id: 44,
    label: "Public figure",
    sub_categories: []
}, {
    id: 154,
    label: "Fitness and nutrition service",
    sub_categories: []
}, {
    id: 366,
    label: "Hobby store",
    sub_categories: []
}, {
    id: 15,
    label: "Art gallery",
    sub_categories: []
}, {
    id: 361,
    label: "Garden center",
    sub_categories: []
}, {
    id: 191,
    label: "Grocery store",
    sub_categories: []
}, {
    id: 97,
    label: "Specialty grocery store",
    sub_categories: []
}, {
    id: 500,
    label: "Beauty supply store",
    sub_categories: []
}, {
    id: 96,
    label: "Wholesale & supply store",
    sub_categories: []
}, {
    id: 60,
    label: "Publisher",
    sub_categories: []
}, {
    id: 541,
    label: "Lighting store",
    sub_categories: []
}, {
    id: 247,
    label: "Lingerie & underwear store",
    sub_categories: []
}, {
    id: 439,
    label: "Pet service",
    sub_categories: []
}, {
    id: 81,
    label: "Lawyer & law firm",
    sub_categories: []
}, {
    id: 409,
    label: "Shopping service",
    sub_categories: []
}, {
    id: 443,
    label: "Home goods store",
    sub_categories: []
}, {
    id: 463,
    label: "Electronics company",
    sub_categories: []
}, {
    id: 344,
    label: "Bakery",
    sub_categories: []
}, {
    id: 408,
    label: "Entrepreneur",
    sub_categories: []
}, {
    id: 274,
    label: "Vitamin and supplements store",
    sub_categories: []
}, {
    id: 319,
    label: "Cars",
    sub_categories: []
}, {
    id: 543,
    label: "Construction & manufacturing",
    sub_categories: []
}, {
    id: 556,
    label: "Video game",
    sub_categories: []
}, {
    id: 333,
    label: "Education website",
    sub_categories: []
}, {
    id: 225,
    label: "Sports & fitness instruction",
    sub_categories: []
}, {
    id: 293,
    label: "Fashion designer",
    sub_categories: []
}, {
    id: 223,
    label: "Magazine",
    sub_categories: []
}, {
    id: 265,
    label: "Information technology company",
    sub_categories: []
}, {
    id: 427,
    label: "Jewelry & watches company",
    sub_categories: []
}, {
    id: 288,
    label: "Animals & pets",
    sub_categories: []
}, {
    id: 129,
    label: "Florist",
    sub_categories: []
}, {
    id: 207,
    label: "Mattress store",
    sub_categories: []
}, {
    id: 529,
    label: "Adult(in brazil)",
    sub_categories: []
}, {
    id: 632,
    label: "Fitness equipment store",
    sub_categories: []
}, {
    id: 281,
    label: "Hardware store",
    sub_categories: []
}, {
    id: 346,
    label: "Digital creator",
    sub_categories: []
}, {
    id: 474,
    label: "Department store",
    sub_categories: []
}, {
    id: 206,
    label: "Travel and tourism",
    sub_categories: [{
        id: 34,
        label: "Other travel and tourism"
    }, {
        id: 35,
        label: "Travel and tourism - other"
    }, {
        id: 113,
        label: "Accommodation and hotels"
    }, {
        id: 148,
        label: "Tourist attractions"
    }]
}, {
    id: 462,
    label: "Beauty store",
    sub_categories: []
}, {
    id: 98,
    label: "Camera store",
    sub_categories: []
}, {
    id: 578,
    label: "Board game",
    sub_categories: []
}, {
    id: 572,
    label: "Baby clothing store",
    sub_categories: []
}, {
    id: 554,
    label: "Collectibles store",
    sub_categories: []
}, {
    id: 17,
    label: "Swimwear store",
    sub_categories: []
}, {
    id: 190,
    label: "Photographer",
    sub_categories: []
}, {
    id: 497,
    label: "Dessert shop",
    sub_categories: []
}, {
    id: 638,
    label: "Bookstore",
    sub_categories: []
}, {
    id: 88,
    label: "College & university",
    sub_categories: []
}, {
    id: 165,
    label: "Medical supply store",
    sub_categories: []
}, {
    id: 377,
    label: "Hobbies & crafts",
    sub_categories: []
}, {
    id: 358,
    label: "Pharmacy",
    sub_categories: []
}, {
    id: 250,
    label: "Restaurants & bars",
    sub_categories: []
}]

import { auth } from "../config/firebase";
import { BE_API_BASE_URL } from "../config/constants";
const baseUrl = BE_API_BASE_URL;

export const getCheckoutSession = async (priceId, redirectPath) => {
  try {
    const accessToken = auth.currentUser.accessToken;
    const response = await fetch(`${baseUrl}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        priceId,
        redirectPath,
      }),
    });

    if (!response.ok) throw new Error("Network response was not ok.");
    const data = await response.json();

    // Redirect user to Stripe Checkout page
    window.location.href = data.url;

    return data;
  } catch (error) {
    console.error("Error getting checkout session:", error);
    return;
  }
};

export const getPortalSession = async (redirectPath) => {
  try {
    const accessToken = auth.currentUser.accessToken;
    const response = await fetch(`${baseUrl}/create-portal-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        redirectPath,
      }),
    });

    if (!response.ok) throw new Error("Network response was not ok.");
    const data = await response.json();

    window.location.href = data.url;

    return data;
  } catch (error) {
    console.error("Error getting portal session:", error);
    return;
  }
};

import { FaFacebookSquare, FaInstagram, FaShopify } from "react-icons/fa";
import { SiTrustpilot } from "react-icons/si";
import { FaMeta } from "react-icons/fa6";
import { FiExternalLink } from "react-icons/fi";
import { useEffect, useContext, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import CoverImage from "../../components/CoverImage";
import { getBrandById } from "../../api/brands";
import ProfileImage from "../../components/ProfileImage";
import TabView from "../../components/TabView";
import ItemDetailsContext from "./ItemDetailsContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialTabs = [
  { name: "Overview", href: `/brands/:brandId/overview`, current: true },
  { name: "Products", href: `/brands/:brandId/products`, current: false },
];

export default function BrandDetailLayout() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);

  const { brandId } = useParams();
  const [itemDetails, setItemDetails] = useState(null);

  useEffect(() => {
    const fetchBrandDetails = async () => {
      try {
        const response = await getBrandById(brandId);
        setItemDetails(response);
      } catch (error) {
        console.error("Failed to fetch brand details:", error);
      }
    };

    fetchBrandDetails();
  }, [brandId]);

  if (!itemDetails) {
    return <div className="m-6">Loading...</div>;
  }

  return (
    <ItemDetailsContext.Provider value={itemDetails}>
      <>
        <div className="relative isolate overflow-hidden bg-gray-900 py-20 sm:py-20">
          <CoverImage
            item={itemDetails}
            alt=""
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
        <main className="py-4 px-8">
          <ProfileImage
            className="-mt-40 z-10 relative h-32 w-32 flex-shrink-0 rounded-full ring-2 ring-white ring-offset-2 ring-offset-white"
            item={itemDetails}
          />
          <div className="sm:flex sm:items-end sm:space-x-5">
            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {itemDetails.brand_name}
                </h1>
              </div>
              <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                {/* <div className="inline-flex justify-center bg-white px-3 py-3 text-sm font-semibold text-gray-900">
                  <StarIconToggle
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </div> */}

                {itemDetails.fb_url && (
                  <a
                    href={itemDetails.fb_url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                  >
                    <FaFacebookSquare
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </a>
                )}

                {/* TODO clean up "NULL' */}
                {itemDetails.ig_url && itemDetails.ig_url !== "NULL" && (
                  <a
                    href={itemDetails.ig_url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                  >
                    <FaInstagram
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </a>
                )}

                {itemDetails.fb_ads_library_url && (
                  <a
                    href={itemDetails.fb_ads_library_url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                  >
                    <FaMeta
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </a>
                )}

                {itemDetails.trustpilot_url && (
                  <a
                    href={itemDetails.trustpilot_url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                  >
                    <SiTrustpilot
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </a>
                )}

                {itemDetails.shopify_url && (
                  <a
                    href={itemDetails.shopify_url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                  >
                    <FaShopify
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </a>
                )}

                {/* TODO clean up "NULL' */}
                {itemDetails.brand_url && itemDetails.brand_url !== "NULL" && (
                  <a
                    href={itemDetails.brand_url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                  >
                    <FiExternalLink
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </a>
                )}
                {/* <a
                  href={itemDetails.brand_url}
                  target="_blank"
                  className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                >
                  <span>Visit Site</span>
                  <FiExternalLink
                    className="ml-4 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </a> */}
              </div>
            </div>
          </div>
          <span className="mt-4 inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
            {itemDetails.categories}
          </span>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {itemDetails.brand_description}
          </p>
          <div className="h-full scroll-smooth bg-white antialiased">
            <TabView initialTabs={initialTabs} />
            <Outlet item={itemDetails} />
          </div>
        </main>
      </>
    </ItemDetailsContext.Provider>
  );
}

import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { Container } from "../../components/Container";
import backgroundImage from "../../images/background-faqs.jpg";

const faqs = [
  {
    question: "Is there a free trial?",
    answer:
      "Yes! We'll know you'll love TrendSearch, so we offer a 7-day free trial on all plans.",
  },
  {
    question: "What's the best thing about TrendSearch?",
    answer:
      "Different users love different things. For most its the ability to discover new brands, products and niches. For others its the ability to monitor competition and get inspiration all in one place. New use cases are being discovered every day.",
  },
  {
    question: "How often is data updated?",
    answer:
      "Different metrics are updated at different intervals and we are improving the frequency of updates on a weekly basis.",
  },
  {
    question: "Do you take feature requests?",
    answer:
      "Absolutely, at TrendSearch we want to continue to deliver the best product hands down. Share how you use TrendSearch and what you'd like to see next via Chat. If it's viable, we'll make it work.",
  },
  {
    question: "My question isn't here?",
    answer:
      "Reach out to support via Chat and we'll help answer any additional questions!",
  },
];

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
        unoptimized="true"
      />
      <Container className="relative">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">
                            {faq.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </Container>
    </section>
  );
}

import { useState, useEffect } from "react";
import { auth } from "../../config/firebase";
import { getPlan } from "../../api/subscriptions";
import PricingTable from "../../components/PricingTable";

export default function PlanContent() {
  const [userTier, setUserTier] = useState(null);
  const [userInterval, setUserInterval] = useState(null);

  const user = auth.currentUser;

  useEffect(() => {
    if (!user) throw new Error("No authenticated user found");
    fetchPlan();
  }, [user]);

  const fetchPlan = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No authenticated user found");
      }

      const { tier, interval } = await getPlan();

      setUserTier(tier);
      setUserInterval(interval);
    } catch (error) {
      console.error("Error fetching custom claims:", error);
    }
  };

  return (
    <>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <p className="mt-6 text-sm font-semibold leading-6 text-gray-600">
            Manage your plan
          </p>
        </div>
      </div>
      <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"></dl>
      <PricingTable userTier={userTier} userInterval={userInterval} />
    </>
  );
}

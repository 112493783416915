import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TabView({ initialTabs }) {
  const { brandId } = useParams();
  const [tabs, setTabs] = useState(initialTabs);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let currentPath = location.pathname.split("/").slice(-1)[0];
    const updatedTabs = initialTabs.map((tab) => ({
      ...tab,
      current:
        (brandId ? tab.href.split("/").slice(-1)[0] : tab.href) === currentPath,
    }));

    setTabs(updatedTabs);
  }, [location, brandId, initialTabs]); // Depend on location, brandId, and initialTabs

  const updatePath = (path) => {
    return path.replace(":brandId", brandId);
  };

  const handleSelectChange = (event) => {
    const selectedTabName = event.target.value;
    const selectedTab = tabs.find((tab) => tab.name === selectedTabName);
    if (selectedTab) {
      navigate(updatePath(selectedTab.href));
    }
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={handleSelectChange} // Add onChange handler
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={updatePath(tab.href)}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

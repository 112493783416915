import { useState, useContext } from "react";
import { numberFormatter } from "../../utils/numberFormatter";
import ItemDetailsContext from "./ItemDetailsContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BrandDetailPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const itemDetails = useContext(ItemDetailsContext);

  if (!itemDetails) {
    return <div></div>;
  }

  const stats = [
    {
      name: "Monthly Traffic",
      value: numberFormatter(itemDetails.estimated_monthly_traffic) || "-",
      // change: "-1.39%",
      changeType: "positive",
    },
    {
      name: "Monthly Revenue",
      value: `${numberFormatter(itemDetails.estimated_monthly_revenue) ? `$${numberFormatter(itemDetails.estimated_monthly_revenue)}` : "-"}`,
      // change: "+10.18%",
      changeType: "negative",
    },
    {
      name: "Facebook Likes",
      value: numberFormatter(itemDetails.fb_likes_count) || "-",
      // change: "+4.75%",
      changeType: "positive",
    },
    {
      name: "Instagram Followers",
      value: numberFormatter(itemDetails.ig_followers_count) || "-",
      // change: "+54.02%",
      changeType: "negative",
    },
    {
      name: "Reviews",
      value: numberFormatter(itemDetails.trustpilot_reviews_count) || "-",
      // change: "+54.02%",
      changeType: "negative",
    },
  ];

  return (
    <>
      {/* Stats */}
      {/* <dl className="grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col bg-gray-400/5 p-8">
                  <dt className="text-sm font-semibold leading-6 text-gray-600">
                    {stat.name}
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl> */}
      <div className="border-b border-b-gray-900/10">
        <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 lg:px-2 xl:px-0">
          {stats.map((stat, statIdx) => (
            <div
              key={stat.name}
              className={classNames(
                [1, 2, 3, 4, 5].includes(statIdx) && "sm:border-l",
                // ![1, 2, 3].includes(statIdx) && "lg:border-l",
                "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
              )}
            >
              <dt className="text-sm font-medium leading-6 text-gray-500">
                {stat.name}
              </dt>
              <dd
                className={classNames(
                  stat.changeType === "negative"
                    ? "text-rose-600"
                    : "text-gray-700",
                  "text-xs font-medium"
                )}
              >
                {stat.change}
              </dd>
              <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  );
}

import { Link } from "react-router-dom";

export function Logo(props) {
  const link = props.link || "/";
  return (
    <Link to={link}>
      <svg
        viewBox="-63.116 511.57 612.574 100.15"
        height="35px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 65.192 583.522 L 65.192 549.442 L 51.752 549.442 L 51.752 541.522 L 88.352 541.522 L 88.352 549.442 L 74.912 549.442 L 74.912 583.522 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 92.184 583.522 L 92.184 551.242 L 101.124 551.242 L 101.124 560.362 L 99.864 557.722 Q 101.304 554.302 104.484 552.532 Q 107.664 550.762 112.224 550.762 L 112.224 559.402 Q 111.624 559.342 111.144 559.312 Q 110.664 559.282 110.124 559.282 Q 106.284 559.282 103.914 561.472 Q 101.544 563.662 101.544 568.282 L 101.544 583.522 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 133.525 584.002 Q 128.005 584.002 123.835 581.842 Q 119.665 579.682 117.385 575.932 Q 115.105 572.182 115.105 567.382 Q 115.105 562.522 117.355 558.772 Q 119.605 555.022 123.505 552.892 Q 127.405 550.762 132.325 550.762 Q 137.065 550.762 140.875 552.772 Q 144.685 554.782 146.905 558.532 Q 149.125 562.282 149.125 567.502 Q 149.125 568.042 149.065 568.732 Q 149.005 569.422 148.945 570.022 L 122.725 570.022 L 122.725 564.562 L 144.025 564.562 L 140.425 566.182 Q 140.425 563.662 139.405 561.802 Q 138.385 559.942 136.585 558.892 Q 134.785 557.842 132.385 557.842 Q 129.985 557.842 128.155 558.892 Q 126.325 559.942 125.305 561.832 Q 124.285 563.722 124.285 566.302 L 124.285 567.742 Q 124.285 570.382 125.455 572.392 Q 126.625 574.402 128.755 575.452 Q 130.885 576.502 133.765 576.502 Q 136.345 576.502 138.295 575.722 Q 140.245 574.942 141.865 573.382 L 146.845 578.782 Q 144.625 581.302 141.265 582.652 Q 137.905 584.002 133.525 584.002 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 174.985 550.762 Q 178.825 550.762 181.855 552.292 Q 184.885 553.822 186.625 556.972 Q 188.365 560.122 188.365 565.042 L 188.365 583.522 L 179.005 583.522 L 179.005 566.482 Q 179.005 562.582 177.295 560.722 Q 175.585 558.862 172.465 558.862 Q 170.245 558.862 168.475 559.792 Q 166.705 560.722 165.715 562.642 Q 164.725 564.562 164.725 567.562 L 164.725 583.522 L 155.365 583.522 L 155.365 551.242 L 164.305 551.242 L 164.305 560.182 L 162.625 557.482 Q 164.365 554.242 167.605 552.502 Q 170.845 550.762 174.985 550.762 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 210.921 584.002 Q 206.361 584.002 202.701 581.932 Q 199.041 579.862 196.911 576.142 Q 194.781 572.422 194.781 567.382 Q 194.781 562.282 196.911 558.562 Q 199.041 554.842 202.701 552.802 Q 206.361 550.762 210.921 550.762 Q 215.001 550.762 218.061 552.562 Q 221.121 554.362 222.801 558.022 Q 224.481 561.682 224.481 567.382 Q 224.481 573.022 222.861 576.712 Q 221.241 580.402 218.211 582.202 Q 215.181 584.002 210.921 584.002 Z M 212.541 576.322 Q 214.821 576.322 216.681 575.242 Q 218.541 574.162 219.651 572.152 Q 220.761 570.142 220.761 567.382 Q 220.761 564.562 219.651 562.582 Q 218.541 560.602 216.681 559.522 Q 214.821 558.442 212.541 558.442 Q 210.201 558.442 208.341 559.522 Q 206.481 560.602 205.371 562.582 Q 204.261 564.562 204.261 567.382 Q 204.261 570.142 205.371 572.152 Q 206.481 574.162 208.341 575.242 Q 210.201 576.322 212.541 576.322 Z M 221.001 583.522 L 221.001 576.922 L 221.181 567.322 L 220.581 557.782 L 220.581 539.002 L 229.941 539.002 L 229.941 583.522 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 253.037 584.242 Q 247.997 584.242 243.377 582.892 Q 238.757 581.542 235.937 579.382 L 239.237 572.062 Q 241.937 573.982 245.627 575.212 Q 249.317 576.442 253.097 576.442 Q 255.977 576.442 257.747 575.872 Q 259.517 575.302 260.357 574.282 Q 261.197 573.262 261.197 571.942 Q 261.197 570.262 259.877 569.272 Q 258.557 568.282 256.397 567.652 Q 254.237 567.022 251.627 566.452 Q 249.017 565.882 246.407 565.042 Q 243.797 564.202 241.637 562.882 Q 239.477 561.562 238.127 559.402 Q 236.777 557.242 236.777 553.882 Q 236.777 550.282 238.727 547.312 Q 240.677 544.342 244.607 542.572 Q 248.537 540.802 254.477 540.802 Q 258.437 540.802 262.277 541.732 Q 266.117 542.662 269.057 544.522 L 266.057 551.902 Q 263.117 550.222 260.177 549.412 Q 257.237 548.602 254.417 548.602 Q 251.597 548.602 249.797 549.262 Q 247.997 549.922 247.217 550.972 Q 246.437 552.022 246.437 553.402 Q 246.437 555.022 247.757 556.012 Q 249.077 557.002 251.237 557.602 Q 253.397 558.202 256.007 558.802 Q 258.617 559.402 261.227 560.182 Q 263.837 560.962 265.997 562.282 Q 268.157 563.602 269.507 565.762 Q 270.857 567.922 270.857 571.222 Q 270.857 574.762 268.877 577.702 Q 266.897 580.642 262.967 582.442 Q 259.037 584.242 253.037 584.242 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 292.871 584.002 Q 287.351 584.002 283.181 581.842 Q 279.011 579.682 276.731 575.932 Q 274.451 572.182 274.451 567.382 Q 274.451 562.522 276.701 558.772 Q 278.951 555.022 282.851 552.892 Q 286.751 550.762 291.671 550.762 Q 296.411 550.762 300.221 552.772 Q 304.031 554.782 306.251 558.532 Q 308.471 562.282 308.471 567.502 Q 308.471 568.042 308.411 568.732 Q 308.351 569.422 308.291 570.022 L 282.071 570.022 L 282.071 564.562 L 303.371 564.562 L 299.771 566.182 Q 299.771 563.662 298.751 561.802 Q 297.731 559.942 295.931 558.892 Q 294.131 557.842 291.731 557.842 Q 289.331 557.842 287.501 558.892 Q 285.671 559.942 284.651 561.832 Q 283.631 563.722 283.631 566.302 L 283.631 567.742 Q 283.631 570.382 284.801 572.392 Q 285.971 574.402 288.101 575.452 Q 290.231 576.502 293.111 576.502 Q 295.691 576.502 297.641 575.722 Q 299.591 574.942 301.211 573.382 L 306.191 578.782 Q 303.971 581.302 300.611 582.652 Q 297.251 584.002 292.871 584.002 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 333.613 583.522 L 333.613 577.222 L 333.013 575.842 L 333.013 564.562 Q 333.013 561.562 331.183 559.882 Q 329.353 558.202 325.573 558.202 Q 322.993 558.202 320.503 559.012 Q 318.013 559.822 316.273 561.202 L 312.913 554.662 Q 315.553 552.802 319.273 551.782 Q 322.993 550.762 326.833 550.762 Q 334.213 550.762 338.293 554.242 Q 342.373 557.722 342.373 565.102 L 342.373 583.522 Z M 323.773 584.002 Q 319.993 584.002 317.293 582.712 Q 314.593 581.422 313.153 579.202 Q 311.713 576.982 311.713 574.222 Q 311.713 571.342 313.123 569.182 Q 314.533 567.022 317.593 565.792 Q 320.653 564.562 325.573 564.562 L 334.153 564.562 L 334.153 570.022 L 326.593 570.022 Q 323.293 570.022 322.063 571.102 Q 320.833 572.182 320.833 573.802 Q 320.833 575.602 322.243 576.652 Q 323.653 577.702 326.113 577.702 Q 328.453 577.702 330.313 576.592 Q 332.173 575.482 333.013 573.322 L 334.453 577.642 Q 333.433 580.762 330.733 582.382 Q 328.033 584.002 323.773 584.002 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 350.829 583.522 L 350.829 551.242 L 359.769 551.242 L 359.769 560.362 L 358.509 557.722 Q 359.949 554.302 363.129 552.532 Q 366.309 550.762 370.869 550.762 L 370.869 559.402 Q 370.269 559.342 369.789 559.312 Q 369.309 559.282 368.769 559.282 Q 364.929 559.282 362.559 561.472 Q 360.189 563.662 360.189 568.282 L 360.189 583.522 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 391.749 584.002 Q 386.529 584.002 382.449 581.872 Q 378.369 579.742 376.059 575.962 Q 373.749 572.182 373.749 567.382 Q 373.749 562.522 376.059 558.772 Q 378.369 555.022 382.449 552.892 Q 386.529 550.762 391.749 550.762 Q 396.849 550.762 400.629 552.892 Q 404.409 555.022 406.209 558.982 L 398.949 562.882 Q 397.689 560.602 395.799 559.522 Q 393.909 558.442 391.689 558.442 Q 389.289 558.442 387.369 559.522 Q 385.449 560.602 384.339 562.582 Q 383.229 564.562 383.229 567.382 Q 383.229 570.202 384.339 572.182 Q 385.449 574.162 387.369 575.242 Q 389.289 576.322 391.689 576.322 Q 393.909 576.322 395.799 575.272 Q 397.689 574.222 398.949 571.882 L 406.209 575.842 Q 404.409 579.742 400.629 581.872 Q 396.849 584.002 391.749 584.002 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 430.981 550.762 Q 434.821 550.762 437.851 552.292 Q 440.881 553.822 442.621 556.972 Q 444.361 560.122 444.361 565.042 L 444.361 583.522 L 435.001 583.522 L 435.001 566.482 Q 435.001 562.582 433.291 560.722 Q 431.581 558.862 428.461 558.862 Q 426.241 558.862 424.471 559.792 Q 422.701 560.722 421.711 562.642 Q 420.721 564.562 420.721 567.562 L 420.721 583.522 L 411.361 583.522 L 411.361 539.002 L 420.721 539.002 L 420.721 560.182 L 418.621 557.482 Q 420.361 554.242 423.601 552.502 Q 426.841 550.762 430.981 550.762 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 456.363 584.002 Q 453.963 584.002 452.253 582.352 Q 450.543 580.702 450.543 578.122 Q 450.543 575.542 452.253 573.952 Q 453.963 572.362 456.363 572.362 Q 458.823 572.362 460.503 573.952 Q 462.183 575.542 462.183 578.122 Q 462.183 580.702 460.503 582.352 Q 458.823 584.002 456.363 584.002 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 468.542 583.522 L 468.542 551.242 L 477.902 551.242 L 477.902 583.522 Z M 473.222 546.742 Q 470.642 546.742 469.022 545.242 Q 467.402 543.742 467.402 541.522 Q 467.402 539.302 469.022 537.802 Q 470.642 536.302 473.222 536.302 Q 475.802 536.302 477.422 537.712 Q 479.042 539.122 479.042 541.342 Q 479.042 543.682 477.452 545.212 Q 475.862 546.742 473.222 546.742 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 501.957 584.002 Q 496.797 584.002 492.807 581.842 Q 488.817 579.682 486.507 575.932 Q 484.197 572.182 484.197 567.382 Q 484.197 562.522 486.507 558.772 Q 488.817 555.022 492.807 552.892 Q 496.797 550.762 501.957 550.762 Q 507.057 550.762 511.077 552.892 Q 515.097 555.022 517.377 558.742 Q 519.657 562.462 519.657 567.382 Q 519.657 572.182 517.377 575.932 Q 515.097 579.682 511.077 581.842 Q 507.057 584.002 501.957 584.002 Z M 501.957 576.322 Q 504.297 576.322 506.157 575.242 Q 508.017 574.162 509.097 572.152 Q 510.177 570.142 510.177 567.382 Q 510.177 564.562 509.097 562.582 Q 508.017 560.602 506.157 559.522 Q 504.297 558.442 501.957 558.442 Q 499.617 558.442 497.757 559.522 Q 495.897 560.602 494.787 562.582 Q 493.677 564.562 493.677 567.382 Q 493.677 570.142 494.787 572.152 Q 495.897 574.162 497.757 575.242 Q 499.617 576.322 501.957 576.322 Z"
          fill="rgb(51, 51, 51);"
        />
        <path
          d="M 20.142 527.688 L -29.457 527.688 C -38.586 527.688 -45.989 535.09 -45.989 544.22 L -45.989 577.287 C -45.989 586.417 -38.586 593.82 -29.457 593.82 L 20.142 593.82 C 29.274 593.82 36.676 586.417 36.676 577.287 L 36.676 544.22 C 36.676 535.09 29.274 527.688 20.142 527.688 Z M 25.653 567.45 C 25.558 568.134 25.091 568.707 24.441 568.937 C 23.764 569.187 23.004 569.051 22.457 568.581 L 14.191 560.312 L -1.212 575.715 C -3.365 577.871 -6.857 577.871 -9.01 575.715 L -16.147 568.581 L -26.067 578.416 C -29.069 581.418 -34.193 580.044 -35.293 575.945 C -35.802 574.042 -35.257 572.012 -33.865 570.619 L -20.088 556.841 C -17.934 554.686 -14.442 554.686 -12.29 556.841 L -5.153 563.979 L 6.365 552.488 L -1.9 544.22 C -2.815 543.431 -2.531 541.946 -1.389 541.549 C -1.191 541.48 -0.981 541.452 -0.771 541.466 L 23.67 541.466 C 24.157 541.458 24.632 541.612 25.02 541.907 C 25.322 542.209 25.709 542.595 25.709 543.008 L 25.653 567.45 Z"
          fill="#231F20"
        />
      </svg>
    </Link>
  );
}

import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./context/AuthProvider";
import { Loader } from "./components/Loader";

function ProtectedRoute({ children }) {
  const { user, isInitialized, userHasClaims, userIsPaid } = useAuth();
  const location = useLocation();

  if (!isInitialized) {
    return <Loader />;
  }
  if (isInitialized && user && user.emailVerified && !userHasClaims) {
    return <Loader />;
  }

  if (
    isInitialized &&
    user &&
    user.emailVerified &&
    userHasClaims &&
    !userIsPaid
  ) {
    if (location.pathname !== "/select-plan") {
      return <Navigate to="/select-plan" replace />;
    } else {
      return children;
    }
  }

  if (
    isInitialized &&
    user &&
    user.emailVerified &&
    userHasClaims &&
    userIsPaid
  ) {
    if (location.pathname == "/select-plan") {
      return <Navigate to="/settings/plan" replace />;
    } else {
      return children;
    }
  }

  if (isInitialized && !user) {
    return <Navigate to="/login" replace />;
  }
}

export default ProtectedRoute;

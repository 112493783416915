import { auth, googleProvider } from "../config/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
} from "firebase/auth";
import { db } from "../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { displaySuccess, displayError } from "../utils/notificationHandler";
import { FE_BASE_URL } from "../config/constants";

export const useAuth = () => {
  const register = async (registration) => {
    try {
      const { email, password, firstName, lastName, referralSource } =
        registration;
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Save additional user info in Firestore
      const userRef = doc(db, "users", user.uid); // Create a reference to a Firestore document for the user
      await setDoc(userRef, {
        firstName,
        lastName,
        // email, // Optional, since it's already in Auth
        referralSource,
      });

      await sendEmailVerification(user, {
        url: `${FE_BASE_URL}/login?confirm_email=true`,
      });
      await signOut(auth);
      displaySuccess("Account created successfully. Please verify your email.");
      return true;
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/email-already-in-use":
          displayError("Email already in use. Please login instead.");
          break;
        case "auth/invalid-email":
          displayError("Invalid email. Please try again.");
          break;
        case "auth/weak-password":
          displayError("Weak password. Please try again.");
          break;
        default:
          displayError("Error signing up. Please try again.");
      }

      // try and sign out the user again just in case
      await signOut(auth);
    }
  };

  const signIn = async (email, password) => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      const user = response.user;
      if (user && user.emailVerified === false) {
        displayError("Please verify your email address first.");
        await signOut(auth);
        return;
      }

      return user;
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/invalid-email":
          displayError("Invalid email. Please try again.");
          break;
        case "auth/user-disabled":
          displayError("User disabled. Please reach to support.");
          break;
        case "auth/user-not-found":
          displayError("User not found.");
          break;
        case "auth/wrong-password":
          displayError("Wrong password. Please try again.");
          break;
        case "auth/invalid-credential":
          displayError("Invalid credentials. Please try again.");
          break;
        case "auth/too-many-requests":
          displayError("Too many requests. Please try again later.");
          break;
        default:
          displayError("Error signing in. Please try again.");
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      const user = await signInWithPopup(auth, googleProvider);
      return user;
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/account-exists-with-different-credential":
          displayError(
            "Account exists with different credential. Please login instead."
          );
          break;
        case "auth/cancelled-popup-request":
          displayError("Cancelled popup request. Please try again.");
          break;
        case "auth/popup-closed-by-user":
          displayError("Popup closed by user. Please try again.");
          break;
        default:
          displayError("Error signing in with Google. Please try again.");
      }
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
      switch (error.code) {
        default:
          displayError("Error signing out. Please try again.");
      }
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      displaySuccess("Password reset email sent successfully.");
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/invalid-email":
          displayError("Invalid email. Please try again.");
          break;
        case "auth/user-not-found":
          displayError("User not found.");
          break;
        default:
          displayError("Error sending password reset email. Please try again.");
      }
    }
  };

  const updatePassword = async (currentPassword, newPassword) => {
    try {
      const user = auth.currentUser;
      await reauthenticateWithCredential(user, currentPassword);
      await user.updatePassword(newPassword);
      displaySuccess("Password updated successfully.");
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/wrong-password":
          displayError("Wrong password. Please try again.");
          break;
        case "auth/weak-password":
          displayError("Weak password. Please try again.");
          break;
        default:
          displayError("Error updating password. Please try again.");
      }
    }
  };

  return {
    register,
    signIn,
    logout,
    signInWithGoogle,
    resetPassword,
    updatePassword,
  };
};

import clsx from "clsx";
import "./styles/tailwind.css";
import { FE_BASE_URL } from "./config/constants";

export const metadata = {
  title: {
    metadataBase: new URL(FE_BASE_URL),
    template: "%s - TrendSearch",
    default: "TrendSearch - Discover and track top selling brands",
  },
  description:
    "Discover and track top selling brands. TrendSearch is a platform to find and track top selling brands across various categories and locations. Find out what's trending in your city or category and track their performance over time. Get insights into their social media presence, website traffic, and more. Sign up to get started!",
};

export default function RootLayout({ children }) {
  return (
    <div
      lang="en"
      className={clsx("h-screen scroll-smooth bg-white antialiased")}
    >
      <div className="flex h-full flex-col">{children}</div>
    </div>
  );
}

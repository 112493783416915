import React, { useState, useEffect } from 'react';

export const TypingAnimation = ({ words, interval = 2000 }) => {
  const [index, setIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const changeWord = () => {
      setOpacity(0); // Hide the word
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length); // Change the word after it's hidden
        setOpacity(1); // Show the word again
      }, 500); // Half of the interval to change the word
    };

    const timer = setTimeout(changeWord, interval);
    return () => clearTimeout(timer);
  }, [index, words.length, interval, opacity]);

  // Define the inline styles for the typing animation with transitions
  const typingAnimationStyle = {
    display: 'inline',
    // fontWeight: 'bold',
    opacity: opacity,
    transition: 'opacity 0.3s ease-in-out',
  };

  return (
    <span style={typingAnimationStyle}>{words[index]}</span>
  );
};

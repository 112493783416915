import { useState } from "react";
import { Outlet } from "react-router-dom";
import SettingsTabs from "../../components/TabView";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialTabs = [
  { name: "Profile", href: `profile`, current: true },
  { name: "Plan", href: `plan`, current: false },
  { name: "Billing", href: `billing`, current: false },
];

export default function SettingsLayout({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);

  return (
    <main className="py-4">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-full scroll-smooth bg-white antialiased">
          {/* <h1 className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Settings
          </h1> */}
          <SettingsTabs initialTabs={initialTabs} />
          {/* <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">{
          // children */}
          <Outlet />
          {/* }</div>
        </main> */}
        </div>
      </div>
    </main>
  );
}

import { FaFacebookSquare, FaInstagram, FaShopify } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useState } from "react";
import { numberFormatter } from "../utils/numberFormatter";
import ProfileImage from "./ProfileImage";

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getColor(score) {
  if (score <= 25) {
    return "bg-blue-400"; // Red for low values
  } else if (score <= 50) {
    return "bg-purple-400"; // Yellow for medium values
  } else if (score <= 75) {
    return "bg-blue-400"; // Blue for high values
  } else {
    return "bg-green-400"; // Green for very high values
  }
}

function getFilledIcons(score) {
  // Each icon represents 20% of the score, so divide the score by 20
  const filledIcons = Math.ceil(score / 20);
  return filledIcons;
}

export default function BrandCard({ item }) {
  const placeholderImage =
    "data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='rgba(0,0,0,0.1)' /%3E%3C/svg%3E";
  // State to manage the cover image source
  const [coverImageSrc, setCoverImageSrc] = useState(item.cover_image);
  // const [profileImageSrc, setProfileImageSrc] = useState(item.profileimage);

  return (
    <div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
      {/* <Link to={item.id} target="_blank" rel="noreferrer"> */}
      <Link to={`${item.id}/overview`}>
        <div className="flex flex-col justify-between h-full">
          {/* <div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"> */}
          {/* <Link to={item.id} target="_blank" rel="noreferrer"> */}
          <div className="h-32 w-full overflow-hidden relative bg-gray-100">
            {/* Fixed height container with a fallback background color */}
            <img
              src={coverImageSrc}
              alt=""
              style={{ opacity: "0.95" }}
              className="h-full w-full object-cover object-center pointer-events-none group-hover:opacity-75"
              onError={() => setCoverImageSrc(placeholderImage)}
            />
          </div>
          <div className="-mt-24 relative z-10 flex flex-1 flex-col p-8">
            <ProfileImage
              className="mx-auto h-32 w-32 flex-shrink-0 rounded-full ring-2 ring-white ring-offset-2 ring-offset-white"
              item={item}
            />
            {/* <img
            className="mx-auto h-32 w-32 flex-shrink-0 rounded-full ring-2 ring-white ring-offset-2 ring-offset-white"
            src={profileImageSrc}
            alt=""
            onError={() => setProfileImageSrc(placeholderImage)}
          /> */}
            {/* </div>
        <div> */}
            <h2 className="mt-6 text-xl font-medium text-gray-900">
              {item.brand_name}
            </h2>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Name</dt>
              {/* <dd className="text-sm text-gray-500">{item.description}</dd>
            <dt className="sr-only">Role</dt> */}
              {/* <dd className="text-sm text-gray-500">{item.category_name}</dd>
            <dt className="sr-only">Role</dt> */}
              <dd className="mt-3">
                <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                  {item.categories}
                </span>
              </dd>
            </dl>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            {/* <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Revenue</dt>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-200">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${item.revenue_score}%`, maxWidth: "200px" }}
              ></div>
            </div>
          </div> */}
            {/* <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Scale</dt>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-200">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${item.scale_score}%`, maxWidth: "200px" }}
              ></div>
            </div>
          </div> */}

            {/* <div className="flex items-center gap-x-4 py-3">
            <dt className="text-gray-500 flex-none">Scale</dt>
            <div className="flex-grow bg-gray-200 rounded-full h-2.5 dark:bg-gray-200">
              <div
                className={`h-2.5 rounded-full ${getColor(item.scale_score)}`}
                style={{ width: `${item.scale_score}%` }}
              ></div>
            </div>
          </div> */}

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Monthly Traffic</dt>
              <dd className="text-gray-700">
                {numberFormatter(item.estimated_monthly_traffic)}
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Facebook Likes</dt>
              <dd className="text-gray-700">
                {numberFormatter(item.fb_likes_count)
                  ? `${numberFormatter(item.fb_likes_count)}`
                  : "-"}
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Instagram Followers</dt>
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900">
                  {numberFormatter(item.ig_followers_count)
                    ? `${numberFormatter(item.ig_followers_count)}`
                    : "-"}
                </div>
                {/* <div
                className={classNames(
                  statuses["Paid"],
                  "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                )}
              >
                {"10%"}
              </div> */}
              </dd>
            </div>
          </dl>
        </div>
      </Link>

      <div className="mt-auto">
        <div className="-mt-px flex divide-x divide-gray-100">
          {item.fb_url && (
            <div className="flex w-0 flex-1">
              <a
                href={item.fb_url}
                target="_blank"
                rel="noreferrer"
                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              >
                <FaFacebookSquare
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </a>
            </div>
          )}

          {/* TODO clean up "NULL' */}
          {item.ig_url && item.ig_url !== "NULL" && (
            <div className="flex w-0 flex-1">
              <a
                href={item.ig_url}
                target="_blank"
                rel="noreferrer"
                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              >
                <FaInstagram
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </a>
            </div>
          )}

          {item.shopify_url && (
            <div className="flex w-0 flex-1">
              <a
                href={item.shopify_url}
                target="_blank"
                rel="noreferrer"
                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              >
                <FaShopify
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </a>
            </div>
          )}

          {/* TODO clean up "NULL' */}
          {item.brand_url && item.brand_url !== "NULL" && (
            <div className="flex w-0 flex-1">
              <a
                href={item.brand_url}
                target="_blank"
                rel="noreferrer"
                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              >
                <FiExternalLink
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

"use client";
import { useState } from "react";
import { getPortalSession } from "../../api/billing";

export default function BillingContent() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);

  return (
    <>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <p className="mt-6 text-sm font-semibold leading-6 text-gray-500">
            Manage your billing
          </p>
          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Billing
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <button
                  // href="https://billing.stripe.com/p/login/test_bIYeYxdgy5sLcykcMM"
                  // target="_blank"
                  // rel="noreferrer"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                  onClick={() => {
                    getPortalSession("/settings/billing");
                  }}
                >
                  View Billing Account
                </button>
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Subscription
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <button
                  // href="https://billing.stripe.com/p/login/test_bIYeYxdgy5sLcykcMM"
                  // target="_blank"
                  // rel="noreferrer"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                  onClick={() => {
                    getPortalSession("/settings/billing");
                  }}
                >
                  Cancel Subscription
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}

import React, { createContext, useContext, useState, useEffect } from "react";
import { filtersWithInputs } from "../../utils/filtersWithInputs";
import { filterBrands } from "../../api/brands";

const FiltersContext = createContext();

export function FiltersContextProvider({ children }) {
  // filter menu = locations + categories + inputs + columns
  // filters selected section = locations + categories
  // inputs section = input fields
  // payload = locations + categories + inputs
  // columns = columns

  const [filtersAndInputs, setFiltersAndInputs] = useState(filtersWithInputs);
  const [activeFilters, setActiveFilters] = useState([]);
  // const [activeInputFields, setActiveInputFields] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [existingFilterPayload, setExistingFilterPayload] = useState({});
  const [filterPayload, setFilterPayload] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const [pageItems, setPageItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    let tableColumns = [];
    for (let i = 0; i < filtersAndInputs.length; i++) {
      for (let j = 0; j < filtersAndInputs[i].options.length; j++) {
        if (filtersAndInputs[i].options[j].hasColumn) {
          tableColumns.push(filtersAndInputs[i].options[j]);
        }
      }
    }
    setTableColumns(tableColumns);

    fetchData(filterPayload, 1);
  }, []);

  useEffect(() => {
    const filters = filtersAndInputs.reduce((acc, filterSection) => {
      const options = filterSection.options.reduce((optionsAcc, option) => {
        if (option.checked) {
          if (option.isBool) {
            optionsAcc[option.dataKey] = option.enabled;
          } else {
            optionsAcc[option.dataKey] = {
              min: option.minSet,
              max: option.maxSet,
            };
          }
        }
        return optionsAcc;
      }, {});
      return { ...acc, ...options };
    }, {});

    const locations = selectedLocations.map((location) => location.id);
    const categories = selectedCategories.map((category) =>
      category.label.toLowerCase()
    );

    if (locations.length > 0) {
      filters.locations = locations;
    }

    if (categories.length > 0) {
      filters.categories = categories;
    }

    setFilterPayload(filters);
  }, [selectedLocations, selectedCategories, filtersAndInputs]);

  const fetchData = async (payload, page) => {
    try {
      const results = await filterBrands(payload, page);
      setPageItems(results.data);
      setTotalItems(results.count);
      setExistingFilterPayload(filterPayload);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching brands:", error);
      // Handle error, e.g., show a toast notification
    }
  };

  const handleSearchWithFilters = () => {
    fetchData(filterPayload, 1);
  };

  const handlePageChange = (page) => {
    fetchData(existingFilterPayload, page);
  };

  const handleToggleColumn = (columnId) => {
    setTableColumns((currentColumns) =>
      currentColumns.map((column) => {
        if (column.dataKey === columnId) {
          return { ...column, columnHidden: !column.columnHidden };
        }
        return column;
      })
    );

    setFiltersAndInputs((currentFilters) =>
      currentFilters.map((section) => {
        return {
          ...section,
          options: section.options.map((option) => {
            if (option.dataKey === columnId) {
              return { ...option, columnHidden: !option.columnHidden };
            }
            return option;
          }),
        };
      })
    );
  };

  const handleLocationSelection = (location) => {
    setSelectedLocations((currentLocations) => {
      if (currentLocations.includes(location)) {
        return currentLocations.filter((loc) => loc !== location);
      } else {
        location.type = "location";
        return [...currentLocations, location];
      }
    });
  };

  const handleCategorySelection = (category) => {
    setSelectedCategories((currentCategories) => {
      if (currentCategories.includes(category)) {
        return currentCategories.filter((cat) => cat !== category);
      } else {
        category.type = "category";
        return [...currentCategories, category];
      }
    });
  };

  const handleRemoveFilter = (filter) => {
    if (filter.type === "location") {
      setSelectedLocations((currentLocations) =>
        currentLocations.filter((loc) => loc !== filter)
      );
    } else if (filter.type === "category") {
      setSelectedCategories((currentCategories) =>
        currentCategories.filter((cat) => cat !== filter)
      );
    }
  };

  useEffect(() => {
    let newActiveFilters = [];
    if (selectedLocations.length > 0) {
      newActiveFilters = [...newActiveFilters, ...selectedLocations];
    }

    if (selectedCategories.length > 0) {
      newActiveFilters = [...newActiveFilters, ...selectedCategories];
    }

    setActiveFilters(newActiveFilters);
  }, [selectedLocations, selectedCategories]);

  return (
    <FiltersContext.Provider
      value={{
        filtersAndInputs,
        setFiltersAndInputs,
        tableColumns,
        setTableColumns,
        selectedLocations,
        setSelectedLocations,
        selectedCategories,
        setSelectedCategories,
        activeFilters,
        setActiveFilters,
        filterPayload,
        setFilterPayload,
        currentPage,
        setCurrentPage,
        items,
        setItems,
        pageItems,
        setPageItems,
        totalItems,
        setTotalItems,
        itemsPerPage,
        setItemsPerPage,
        handlePageChange,
        handleLocationSelection,
        handleCategorySelection,
        handleSearchWithFilters,
        handleToggleColumn,
        handleRemoveFilter,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export const useFilters = () => useContext(FiltersContext);

import { FiltersContextProvider } from "../Search/FiltersContext";
import FilterDialog from "../Search/FilterDialog";
import ActiveFiltersDisplay from "../Search/ActiveFiltersDisplay";
import FilterSection from "../Search/FilterSection";
import InputFieldFilters from "../Search/InputFieldFilters";
import DataTable from "../Search/DataTable";

const SearchPage = () => {
  return (
    <main className="py-4">
      <div className="px-4 sm:px-6 lg:px-8">
        <FiltersContextProvider>
          <div className="bg-white">
            <h1 className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Search Brands
            </h1>
            <p className="mt-4 mb-4 max-w-xl text-sm text-gray-700">
              Search and filter brands in a variety of ways.
            </p>
            <ActiveFiltersDisplay />
            <FilterSection />
            <InputFieldFilters />
            <DataTable />
          </div>
        </FiltersContextProvider>
      </div>
    </main>
  );
};

export default SearchPage;

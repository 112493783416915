import { useEffect, useState } from "react";
import { Dialog, Switch } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../../hooks/auth";
import { auth } from "../../config/firebase";

const secondaryNavigation = [
  { name: "General", href: `/profile`, icon: UserCircleIcon, current: true },
  {
    name: "Security",
    href: `/security`,
    icon: FingerPrintIcon,
    current: false,
  },
  { name: "Plan", href: `/plan`, icon: CubeIcon, current: false },
  { name: "Billing", href: `/billing`, icon: CreditCardIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileContent() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);

  const user = auth.currentUser;
  const { resetPassword } = useAuth();

  const handleResetPassword = () => {
    if (!user) throw new Error("No authenticated user found");

    resetPassword(user.email)
      .then(() => {
        // console.log("Password reset email sent");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {/* <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20"> */}
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <p className="mt-6 text-sm font-semibold leading-6 text-gray-600">
            View your profile
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            {/* <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Name
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {user.displayName || user.email}
                </div>
              </dd>
            </div> */}
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Email address
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{user.email}</div>
                {/* <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Update
                    </button> */}
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Reset Password
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                  onClick={() => handleResetPassword()}
                >
                  Send reset link
                </button>
                {/* <input
                    type="password"
                    name="current-password"
                    id="current-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Current password"
                  />
                  <input
                    type="password"
                    name="new-password"
                    id="new-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="New password"
                  />
                  <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Update
                    </button> */}
                {/* <div className="text-gray-900">Human Resources Manager</div> */}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {/* </main> */}
    </>
  );
}

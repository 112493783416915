import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/Landing/LandingPage";
import LoginPage from "./pages/Auth/LoginPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import ForgotPasswordPage from "./pages/Auth/ForgotPasswordPage";
import SelectPlanPage from "./pages/Plan/SelectPlanPage";
// import SupportPage from "./pages/Support/SupportPage";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import BrandsPage from "./pages/Dashboard/BrandsPage";
import SearchPage from "./pages/Dashboard/SearchPage";
import RootLayout from "./RootLayout";
import SettingsLayout from "./pages/Settings/SettingsLayout";
import ProfileContent from "./pages/Settings/ProfileContent";
import SecurityContent from "./pages/Settings/SecurityContent";
import PlanContent from "./pages/Settings/PlanContent";
import BillingContent from "./pages/Settings/BillingContent";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/AuthProvider";
import BrandDetailLayout from "./pages/Brands/BrandDetailLayout";
import BrandOverview from "./pages/Brands/BrandOverview";
import BrandProducts from "./pages/Brands/BrandProducts";
import useScript from "./hooks/useScript";
import HotjarTracking from "./hooks/HotjarTracking"; // Adjust the import path according to your file structure
import TagManager from "react-gtm-module";
import { GTM_ID } from "./config/constants";

function App() {
  useScript("//code.tidio.co/hkxwohzmm84jqclgjh21pwdtnyb8cwxz.js");

  const tagManagerArgs = {
    gtmId: GTM_ID,
    dataLayer: {
      userId: "visitor-id",
      userProject: "project-id",
    },
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <AuthProvider>
      <HotjarTracking />
      <Toaster />
      <RootLayout>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            {/* <Route path="/support" element={<SupportPage />} /> */}
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <Routes>
                    <Route path="/select-plan" element={<SelectPlanPage />} />
                    <Route path="/" element={<Dashboard />}>
                      <Route path="/brands" element={<BrandsPage />} />
                      <Route path="/brands/search" element={<SearchPage />} />
                      <Route path="/brands" element={<BrandDetailLayout />}>
                        <Route
                          path=":brandId/overview"
                          element={<BrandOverview />}
                        />
                        <Route
                          path=":brandId/products"
                          element={<BrandProducts />}
                        />
                      </Route>
                      <Route path="/settings" element={<SettingsLayout />}>
                        <Route path="profile" element={<ProfileContent />} />
                        <Route path="security" element={<SecurityContent />} />
                        <Route path="plan" element={<PlanContent />} />
                        <Route path="billing" element={<BillingContent />} />
                      </Route>
                    </Route>
                  </Routes>
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </BrowserRouter>
      </RootLayout>
    </AuthProvider>
  );
}

export default App;

import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { intervals, tiers } from "../config/stripe";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Pricing({ onAction }) {
  const [interval, setInterval] = useState(intervals[1]);

  const handeClick = (tier, interval) => (e) => {
    if (onAction) {
      e.preventDefault();
      onAction(tier, interval);
    }
  };

  return (
    <section id="pricing" aria-label="Pricing plans">
      <div className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            {/* <h2 className="text-base font-semibold leading-7 text-indigo-400">
              Pricing
            </h2> */}
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              Simple pricing for every stage
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
            It doesn’t matter whether you’re just starting out, looking for an
            idea or already at scale, we have a plan that fits just right.
          </p>
          <div className="mt-16 flex justify-center">
            <RadioGroup
              value={interval}
              onChange={setInterval}
              className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-s font-semibold leading-5 text-white"
            >
              <RadioGroup.Label className="sr-only">
                Payment interval
              </RadioGroup.Label>
              {intervals.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? "bg-indigo-500" : "",
                      "cursor-pointer rounded-full px-3 py-2"
                    )
                  }
                >
                  <span>{option.label}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <div className="flex items-center justify-center">
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-[900px] lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-white/5 ring-2 ring-indigo-500"
                      : "ring-1 ring-white/10",
                    "rounded-3xl p-8 xl:p-10"
                  )}
                >
                  <div className="flex items-center justify-between gap-x-4">
                    <h3
                      id={tier.id}
                      className="text-lg font-semibold leading-8 text-white"
                    >
                      {tier.name}
                    </h3>
                    {tier.savings[interval.value] ? (
                      <p className="bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-full px-3 py-2 text-l font-semibold leading-5 text-white text-center">
                        {tier.savings[interval.value]}
                      </p>
                    ) : null}
                    {tier.mostPopular ? (
                      <p className="rounded-full bg-indigo-500 px-3 py-2 text-s font-semibold leading-5 text-white">
                        Most popular
                      </p>
                    ) : null}
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-300">
                    {tier.description}
                  </p>

                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-white">
                      {tier.price[interval.value]}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-300">
                      {tier.priceSuffix[interval.value]}
                    </span>
                  </p>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500"
                        : "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white",
                      "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    )}
                    onClick={handeClick(tier, interval)}
                  >
                    Start 7 day trial
                  </a>

                  {/* {tier.savingsDescription[interval.value] ? (
                    <p className="bg-purple-500 mt-8 rounded-full px-3 py-3 text-l font-semibold leading-5 text-white text-center">
                      {tier.savingsDescription[interval.value]}
                    </p>
                  ) : null} */}

                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-white"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ProfileImage from "../../components/ProfileImage";
import Pagination from "../../components/Pagination";
import { numberFormatter } from "../../utils/numberFormatter";
import { urlFormatter } from "../../utils/urlFormatter";
import { useFilters } from "./FiltersContext";

const DataTable = () => {
  const {
    tableColumns,
    currentPage,
    itemsPerPage,
    pageItems,
    totalItems,
    handlePageChange,
  } = useFilters();

  const displayPicture = (item) => {
    return (
      <Link to={`../../brands/${item.id}/overview`} target="_blank">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <ProfileImage className="h-10 w-10 rounded-full" item={item} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {item.brand_name}
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const displayUrl = (url) => {
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="text-indigo-600 hover:text-indigo-900"
      >
        {urlFormatter(url)}
      </a>
    );
  };

  const displayBool = (item) => {
    return (
      <Fragment>
        {item ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            Yes
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
            No
          </span>
        )}
      </Fragment>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          {/* <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Search Brands</h1>
          <p className="mt-2 text-sm text-gray-700">
            Search and column brands by a number of different ways.
          </p>
        </div> */}
          {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add column
          </button>
        </div> */}
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {tableColumns.map((column) => {
                      if (column.hasColumn && !column.columnHidden) {
                        return (
                          <th
                            key={column.dataKey}
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 min-w-[150px]"
                          >
                            {column.columnLabel}
                          </th>
                        );
                      }
                      return null;
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {pageItems.length === 0 ? (
                    <tr>
                      <td
                        colSpan={tableColumns.length}
                        className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 max-w-xs overflow-hidden text-overflow-ellipsis"
                      >
                        No items found.
                      </td>
                    </tr>
                  ) : (
                    pageItems.map((item) => (
                      <tr key={`${item.id}-row`}>
                        {tableColumns.map((column) => {
                          if (column.hasColumn && !column.columnHidden) {
                            let content;
                            if (column.dataKey === "brand_name") {
                              content = displayPicture(item);
                            } else if (column.isBool) {
                              content = displayBool(item[column.dataKey]);
                            } else if (column.dataKey === "brand_url") {
                              content = displayUrl(item[column.dataKey]);
                            } else {
                              content =
                                numberFormatter(item[column.dataKey]) || "-";
                            }

                            return (
                              <td
                                key={column.dataKey}
                                className="px-4 pl-0 py-4 whitespace-nowrap text-sm text-gray-900 max-w-xs overflow-hidden truncate"
                              >
                                {content}
                              </td>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-4">
            <Pagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;

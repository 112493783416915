// "fblikes_diff",
// "instafollowers_diff",
// "trustpilotreviews_diff",
// "fbreviews_diff",
// "shopifyreview_count_diff",
// "fb_ads_diff",
// report_published
// latest_numberfbads
// latest_redditgrowth

export const filtersWithInputs = [
  {
    id: "brand",
    name: "Brand",
    options: [
      {
        dataKey: "brand_name",
        hasColumn: true,
        columnLabel: "Brand",
        columnHidden: false,
      },
    ],
  },
  {
    id: "brand_url",
    name: "Site",
    options: [
      {
        dataKey: "brand_url",
        hasColumn: true,
        columnLabel: "Site",
        columnHidden: false,
      },
    ],
  },
  {
    id: "location",
    name: "Location",
    options: [
      {
        dataKey: "locations",
        label: "",
        checked: false,
        options: [],
        hasColumn: true,
        columnLabel: "Location",
        columnHidden: true,
      },
    ],
  },
  {
    id: "category",
    name: "Category",
    options: [
      {
        dataKey: "categories",
        label: "",
        checked: false,
        options: [],
        hasColumn: true,
        columnLabel: "Category",
        columnHidden: false,
      },
    ],
  },
  {
    id: "social",
    name: "Social",
    options: [
      {
        dataKey: "fb_likes_count",
        label: "Facebook Likes",
        checked: false,
        min: 1,
        max: 31420978,
        hasColumn: true,
        columnLabel: "Facebook Likes",
        columnHidden: false,
      },
      {
        dataKey: "ig_followers_count",
        label: "Instagram Followers",
        checked: false,
        min: 1,
        max: 1083040,
        hasColumn: true,
        columnLabel: "Instagram Followers",
        columnHidden: false,
      },
      {
        dataKey: "reddit_mentions_count",
        label: "Reddit Mentions",
        checked: false,
        min: 1,
        max: 650,
        hasColumn: true,
        columnLabel: "Reddit Mentions",
        columnHidden: true,
      },
    ],
  },
  {
    id: "reviews",
    name: "Reviews",
    options: [
      {
        dataKey: "trustpilot_reviews_count",
        label: "Trustpilot Reviews",
        checked: false,
        min: 1,
        max: 317141,
        hasColumn: true,
        columnLabel: "Trustpilot Reviews",
        columnHidden: true,
      },
      {
        dataKey: "trustpilot_rating",
        label: "Trustpilot Rating",
        checked: false,
        min: 1,
        max: 5,
        hasColumn: true,
        columnLabel: "Trustpilot Rating",
        columnHidden: true,
      },
      {
        dataKey: "shopify_reviews_count",
        label: "Shopify Reviews",
        checked: false,
        min: 1,
        max: 8097745,
        hasColumn: true,
        columnLabel: "Shopify Reviews",
        columnHidden: true,
      },

      {
        dataKey: "fb_reviews_count",
        label: "Facebook Reviews",
        checked: false,
        min: 1,
        max: 888,
        hasColumn: true,
        columnLabel: "Facebook Reviews",
        columnHidden: true,
      },
    ],
  },
  {
    id: "ads",
    name: "Ads",
    options: [
      {
        dataKey: "fb_ads_count",
        label: "Facebook Ads Count",
        checked: false,
        min: 1,
        max: 3699,
        hasColumn: true,
        columnLabel: "Facebook Ads Count",
        columnHidden: true,
      },
      {
        dataKey: "fb_ads_active_count",
        label: "Active Ads",
        checked: false,
        min: 1,
        max: 3346,
        hasColumn: true,
        columnLabel: "Active Ads",
        columnHidden: true,
      },
      {
        dataKey: "is_fb_ads_active",
        label: "Running Facebook Ads",
        checked: false,
        isBool: true,
        hasColumn: true,
        columnLabel: "Running Facebook Ads",
        columnHidden: true,
      },
    ],
  },
  {
    id: "store",
    name: "Store",
    options: [
      {
        dataKey: "estimated_monthly_traffic",
        label: "Estimated Monthly Traffic",
        checked: false,
        min: 1,
        max: 34600000000,
        hasColumn: true,
        columnLabel: "Monthly Traffic",
        columnHidden: false,
      },
      {
        dataKey: "estimated_monthly_orders",
        label: "Estimated Monthly Orders",
        checked: false,
        min: 1,
        max: 1038000000,
        hasColumn: true,
        columnLabel: "Estimated Monthly Orders",
        columnHidden: true,
      },
      {
        dataKey: "estimated_monthly_revenue",
        label: "Estimated Monthly Revenue",
        checked: false,
        min: 1,
        max: 244441235324928,
        hasColumn: true,
        columnLabel: "Estimated Monthly Revenue",
        columnHidden: true,
      },
    ],
  },
  {
    id: "products",
    name: "Products",
    options: [
      {
        dataKey: "product_count",
        label: "Number of Products",
        checked: false,
        min: 1,
        max: 547136,
        hasColumn: true,
        columnLabel: "Number of Products",
        columnHidden: true,
      },
      {
        dataKey: "product_min_price",
        label: "Product Price Minimum",
        checked: false,
        min: 1,
        max: 114000,
        hasColumn: true,
        columnLabel: "Product Price Minimum",
        columnHidden: true,
      },
      {
        dataKey: "product_avg_price",
        label: "Product Price Avgerage",
        checked: false,
        min: 1,
        max: 317044391936,
        hasColumn: true,
        columnLabel: "Product Price Avgerage",
        columnHidden: true,
      },
      {
        dataKey: "product_max_price",
        label: "Product Price Maximum",
        checked: false,
        min: 1,
        max: 1769780197457920,
        hasColumn: true,
        columnLabel: "Product Price Maximum",
        columnHidden: true,
      },
      {
        dataKey: "product_count_30_days",
        label: "New Products - last 30 days",
        checked: false,
        min: 1,
        max: 64874,
        hasColumn: true,
        columnLabel: "New Products - last 30 days",
        columnHidden: true,
      },
    ],
  },
  {
    id: "other",
    name: "Other",
    options: [
      {
        dataKey: "brand_description",
        label: "Description",
        hasColumn: true,
        columnLabel: "Description",
        columnHidden: false,
      },
      {
        dataKey: "is_pinned",
        label: "Pinned",
        checked: false,
        isBool: true,
        hasColumn: true,
        columnLabel: "Pinned",
        columnHidden: true,
      },
    ],
  },
];

import { db, auth } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";

export const getTier = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error("No authenticated user found");

  const userDocRef = doc(db, "user_roles", user.uid);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    const userTier = userDocSnap.data().tier;
    return userTier;
  } else {
    throw new Error("User document does not exist");
  }
};

export const getPlan = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error("No authenticated user found");

  const userDocRef = doc(db, "user_roles", user.uid);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    const userTier = userDocSnap.data().tier;
    const userInterval = userDocSnap.data().interval;

    // retrieve the user's custom roles from the jwt token
    const userToken = await user.getIdTokenResult();
    const jwtTier = userToken.claims.tier;
    const jwtInterval = userToken.claims.interval;

    // check if the user's custom roles match the user document
    if (userTier !== jwtTier || userInterval !== jwtInterval) {
      console.log("User roles do not match the JWT token");
    }

    return { tier: userTier, interval: userInterval };
  } else {
    throw new Error("User document does not exist");
  }
};

export function numberFormatter(num) {
  try {
    if (num === 0) return "-";
    if (isNaN(parseInt(num))) return num;
    if (parseInt(num) === null) return num;

    const suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = 0;
    while (num >= 1000) {
      num /= 1000;
      suffixNum++;
    }
    return num.toFixed(1).replace(/\.?0+$/, "") + suffixes[suffixNum];
  } catch (error) {
    console.error("Error in numberFormatter", error);
    return num;
  }
}

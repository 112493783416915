import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { getCheckoutSession } from "../api/billing";
import { intervals, tiers } from "../config/stripe";
import DialogModal from "./DialogModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingTable({ userTier, userInterval }) {
  const [interval, setInterval] = useState(intervals[0]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    // set interval to user tier
    if (userTier && userInterval) {
      const selectedInterval = intervals.find((i) => i.value === userInterval);
      setInterval(selectedInterval);
    }
  }, [userInterval]);

  function handleSelectPlan() {
    // open Dialog
    setOpenDialog(true);
  }

  return (
    <div className="bg-white py-0 sm:py-0">
      <DialogModal open={openDialog} setOpen={setOpenDialog} />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mt-8 flex justify-center">
          <RadioGroup
            value={interval}
            onChange={setInterval}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment interval
            </RadioGroup.Label>
            {intervals.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? "bg-indigo-600 text-white" : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1"
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "ring-2 ring-indigo-600"
                  : "ring-1 ring-gray-200",
                "rounded-3xl p-8 xl:p-10"
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                    "text-lg font-semibold leading-8"
                  )}
                >
                  {tier.name}
                </h3>
                {tier.savings[interval.value] ? (
                  <p className="bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-full px-3 py-2 text-l font-semibold leading-5 text-white text-center">
                    {tier.savings[interval.value]}
                  </p>
                ) : null}
                {tier.mostPopular ? (
                  <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  {tier.price[interval.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600">
                  {tier.priceSuffix[interval.value]}
                </span>
              </p>

              {(tier.id === userTier && interval.value === userInterval) ||
              (tier.id === userTier && tier.id === "free") ? (
                <button
                  disabled
                  className="mt-6 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-gray-500 bg-gray-200"
                >
                  Current Plan
                </button>
              ) : (
                <button
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                      : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                    "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )}
                  type="submit"
                  onClick={() => {
                    handleSelectPlan();
                    // getCheckoutSession(
                    //   tier.priceId[interval.value],
                    //   "/settings/plan"
                    // );
                  }}
                >
                  Select plan
                </button>
              )}

              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export const intervals = [
  { value: "month", label: "Monthly" },
  { value: "year", label: "Annually - Save 50%" },
];

// PROD
export const tiers = [
  {
    name: "Basic",
    id: "basic",
    href: "/register",
    price: { month: "$29", year: "$19.83" },
    priceId: {
      month: "price_1OocNKHnSr4kyZO4Yr1189gB",
      year: "price_1OocMzHnSr4kyZO4c7BA9zXJ",
    },
    priceSuffix: {
      month: "per month",
      year: `per month - $226 annually`,
    },
    savings: { year: "35% OFF" },
    savingsDescription: {
      year: "Get 4 months FREE when paying annually",
    },
    description: "Just what you need to get started.",
    features: [
      "Search by Brand",
      "10,000+ Brands",
      "Advanced Search by Filters",
      "Max 50 Results per Search",
      "Brand Overview",
      "Social Tracking",
      "Review Tracking",
      "Traffic Data",
      "Revenue Estimate",
    ],
    mostPopular: false,
  },
  {
    name: "Plus",
    id: "plus",
    href: "/register",
    price: { month: "$69", year: "$34.50" },
    priceId: {
      month: "price_1OocOMHnSr4kyZO4D4YBMgrI",
      year: "price_1OocO1HnSr4kyZO4dpBJxPP4",
    },
    priceSuffix: {
      month: "per month",
      year: `per month - $414 annually`,
    },
    savings: { year: "50% OFF" },
    savingsDescription: {
      year: "Get 6 months FREE when paying annually",
    },
    description: "Get complete insights and scale faster.",
    features: [
      "Search by Brand",
      "10,000+ Brands",
      "Advanced Search by Filters",
      "Unlimited Results per Search",
      "Brand Overview",
      "Social Tracking",
      "Review Tracking",
      "Traffic Data",
      "Revenue Estimate",
      "Early Feature Access",
      "Premium Customer Support",
    ],
    mostPopular: true,
  },
];

// DEV
// export const tiers = [
//   {
//     name: "Basic",
//     id: "basic",
//     href: "/register",
//     price: { month: "$29", year: "$19.83" },
//     priceId: {
//       month: "price_1OjjR3HnSr4kyZO4q8MlFHvb",
//       year: "price_1OoncGHnSr4kyZO4c7gFepMR",
//     },
//     priceSuffix: {
//       month: "per month",
//       year: `per month - $226 annually`,
//     },
//     savings: { year: "35% OFF" },
//     savingsDescription: {
//       year: "Get 4 months FREE when paying annually",
//     },
//     description: "Just what you need to get started.",
//     features: [
//       "Search by Brand",
//       "10,000+ Brands",
//       "Advanced Search by Filters",
//       "Max 50 Results per Search",
//       "Brand Overview",
//       "Social Tracking",
//       "Review Tracking",
//       "Traffic Data",
//       "Revenue Estimate",
//     ],
//     mostPopular: false,
//   },
//   {
//     name: "Plus",
//     id: "plus",
//     href: "/register",
//     price: { month: "$69", year: "$34.50" },
//     priceId: {
//       month: "price_1OjjRKHnSr4kyZO446By7b7b",
//       year: "price_1OonaxHnSr4kyZO4WNePeR33",
//     },
//     priceSuffix: {
//       month: "per month",
//       year: `per month - $414 annually`,
//     },
//     savings: { year: "50% OFF" },
//     savingsDescription: {
//       year: "Get 6 months FREE when paying annually",
//     },
//     description: "Get complete insights and scale faster.",
//     features: [
//       "Search by Brand",
//       "10,000+ Brands",
//       "Advanced Search by Filters",
//       "Unlimited Results per Search",
//       "Brand Overview",
//       "Social Tracking",
//       "Review Tracking",
//       "Traffic Data",
//       "Revenue Estimate",
//       "Early Feature Access",
//       "Premium Customer Support",
//     ],
//     mostPopular: true,
//   },
// ];

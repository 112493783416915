import { useState } from "react";

export default function CoverImage({ item, className }) {
  const placeholderImage =
    "data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='rgba(0,0,0,0.1)' /%3E%3C/svg%3E";
  const [imageSrc, setImageSrc] = useState(item.cover_image);

  return (
    <img
      src={imageSrc}
      alt=""
      className={className}
      onError={() => setImageSrc(placeholderImage)}
    />
  );
}

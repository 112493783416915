import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";

import { Button } from "../../components/Button";
import { Container } from "../../components/Container";
import { Logo } from "../../components/Logo";
import { NavLink } from "../../components/NavLink";
import { useAuth } from "../../hooks/auth";

export function PlanHeader() {
  const { logout } = useAuth();

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Logo className="h-10 w-auto" />
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <Button onClick={logout} color="white">
              <span>Sign Out</span>
            </Button>
          </div>
        </nav>
      </Container>
    </header>
  );
}

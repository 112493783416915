// PROD
export const BE_API_BASE_URL =
  "https://trendingstores-be-c28935cef9e8.herokuapp.com/api";
export const FE_BASE_URL = "https://trendsearch.io";
export const GTM_ID = "GTM-559TSZKL";

// DEV
// export const BE_API_BASE_URL = "http://localhost:5500/api";
// export const FE_BASE_URL = "http://localhost:3000";
// export const GTM_ID = "GTM-WX6CVW3F";

import React from "react";
import { useFilters } from "./FiltersContext";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import EyeIconToggle from "../../components/EyeIconToggle";
import InputWithCommas from "../../components/InputWithCommas";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const InputFieldFilters = () => {
  const { filtersAndInputs, setFiltersAndInputs, handleToggleColumn } =
    useFilters();

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <>
        {filtersAndInputs.map((filterSection) => (
          <React.Fragment key={filterSection.id}>
            {filterSection.options.map((filter) => (
              <React.Fragment key={filter.dataKey}>
                <>
                  {filter.checked && filter.max > 1 && (
                    <div className="mt-5">
                      <div className="flex space-x-3">
                        <label className="relative block min-w-[200px] text-sm font-medium leading-6 text-gray-900 flex-shrink-0">
                          {filter.label}
                        </label>
                        <div className="flex-grow max-w-[150px]">
                          <label
                            htmlFor={filter.dataKey + "-min"}
                            className="sr-only"
                          >
                            Min
                          </label>
                          <input
                            type="number"
                            name={filter.dataKey + "-min"}
                            id={filter.dataKey + "-min"}
                            min={filter.min}
                            max={filter.max}
                            defaultValue={filter.min}
                            onChange={(e) => (
                              (filter.minSet = parseInt(e.target.value)),
                              setFiltersAndInputs([...filtersAndInputs])
                            )}
                            className="relative block w-full border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Min"
                          />
                        </div>
                        <div className="flex-grow max-w-[150px]">
                          <label
                            htmlFor={filter.dataKey + "-max"}
                            className="sr-only"
                          >
                            Max
                          </label>
                          <input
                            type="number"
                            name={filter.dataKey + "-max"}
                            id={filter.dataKey + "-max"}
                            min={filter.min}
                            max={filter.max}
                            defaultValue={filter.max}
                            onChange={(e) => (
                              (filter.maxSet = parseInt(e.target.value)),
                              setFiltersAndInputs([...filtersAndInputs])
                            )}
                            className="relative block w-full rounded-br-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Max"
                          />
                        </div>
                        <div className="flex-grow">
                          <div className="flex items-center gap-x-4">
                            <div className="flex items-center min-w-[300px]">
                              <button
                                type="button"
                                onClick={() => {
                                  filter.checked = false;
                                  setFiltersAndInputs([...filtersAndInputs]);
                                }}
                                className="flex items-center justify-center h-8 w-8 rounded-md bg-gray-100 text-gray-400 hover:bg-gray-200"
                              >
                                <span className="sr-only">Remove</span>
                                <XMarkIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                              {/* {filter.hasColumn && (
                                <div className="flex items-center ml-4">
                                  <EyeIconToggle
                                    id={filter.dataKey}
                                    hidden={filter.columnHidden}
                                    onAction={handleToggleColumn}
                                  />
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {filter.checked && filter.isBool && (
                    // {filter.isBool && (
                    <div className="mt-5" key={filter.dataKey}>
                      <div className="flex space-x-3">
                        <label className="relative block min-w-[200px] text-sm font-medium leading-6 text-gray-900 flex-shrink-0">
                          {filter.label}
                        </label>
                        <div className="flex-grow max-w-[150px]">
                          {/* switch */}
                          <Switch.Group as="div" className="flex items-center">
                            <Switch
                              checked={filter.enabled}
                              onChange={(newEnabled) => {
                                filter.enabled = newEnabled;
                                setFiltersAndInputs([...filtersAndInputs]);
                              }}
                              className={classNames(
                                filter.enabled
                                  ? "bg-indigo-600"
                                  : "bg-gray-200",
                                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                              )}
                            >
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  filter.enabled
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                )}
                              />
                            </Switch>
                            <Switch.Label as="span" className="ml-3 text-sm">
                              <span className="font-medium text-gray-900">
                                {filter.enabled ? "Yes" : "No"}
                              </span>{" "}
                            </Switch.Label>
                          </Switch.Group>
                        </div>
                        <div className="flex-grow max-w-[150px]"></div>
                        <div className="flex-grow">
                          {/* X button which removes it */}
                          <button
                            type="button"
                            onClick={() => {
                              filter.checked = false;
                              setFiltersAndInputs([...filtersAndInputs]);
                            }}
                            className="flex items-center justify-center h-8 w-8 rounded-md bg-gray-100 text-gray-400 hover:bg-gray-200"
                          >
                            <span className="sr-only">Remove</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </>
    </div>
  );
};

export default InputFieldFilters;

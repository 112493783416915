import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase"; // Adjust the import path as necessary

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const [userHasClaims, setUserHasClaims] = useState(false);
  const [userIsPaid, setUserIsPaid] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (!user && !loading && !isInitialized) {
      setIsInitialized(true);
    }

    if (user && !userHasClaims) {
      const maxRetries = 5;
      user.getIdTokenResult(true).then((userToken) => {
        if (userToken.claims.tier) {
          setUserHasClaims(true);

          if (
            userToken.claims.tier === "basic" ||
            userToken.claims.tier === "plus"
          ) {
            setUserIsPaid(true);
          } else {
            setUserIsPaid(false);
          }

          setIsInitialized(true);
          return;
        }

        if (retryCount > maxRetries) {
          setIsInitialized(true);
          return;
        }

        if (!userHasClaims && retryCount <= maxRetries) {
          setTimeout(
            () => {
              user.getIdToken(true).then(() => {
                setRetryCount(retryCount + 1);
              });
            },
            2 ** retryCount * 1000
          );
        }
      });
    }
  }, [user, retryCount, loading, isInitialized]);

  useEffect(() => {
    if (!user) {
      setUserHasClaims(false);
      setUserIsPaid(false);
      setIsInitialized(false);
      setRetryCount(0);
    }
  }, [user]);

  useEffect(() => {
    if (user && userHasClaims) {
      user.getIdTokenResult(false).then((userToken) => {
        if (
          userToken.claims.tier === "basic" ||
          userToken.claims.tier === "plus"
        ) {
          setUserIsPaid(true);
        } else {
          setUserIsPaid(false);
        }
      });
    }
  }, [user, userHasClaims]);

  return (
    <AuthContext.Provider
      value={{ user, isInitialized, userHasClaims, loading, error, userIsPaid }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// There is a delay between signing up and claims being updated on server, we are using claims to retrieve the plan details.
// There is a delay between updating / purchasing a plan and the claims being updated on the server.

// Need to handle both of these gracefully...?

import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

import { getBrands } from "../../api/brands";
import BrandCard from "../../components/BrandCard";
import InfiniteScroll from "react-infinite-scroll-component";

export default function BrandsPage() {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const fetchData = async () => {
    try {
      const results = await getBrands(page);
      setItems([...items, ...results.data]);
      setPage(page + 1);
    } catch (error) {
      toast.error("Error fetching brands");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-white">
      {/* <div className="mx-auto max-w-2xl px-4 py-0 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8"> */}
      <InfiniteScroll
        dataLength={items.length} //This is important field to render the next data
        next={fetchData}
        hasMore={true}
        loader={<h4 className="ml-8">Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <div className="px-4 py-0 sm:px-6 sm:py-6 lg:max-w-8xl lg:px-8">
          <h2 className="sr-only">Items</h2>
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
            {items.map((item) => (
              <BrandCard key={item.id} item={item} />
            ))}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
}

import { auth } from "../config/firebase";
import { BE_API_BASE_URL } from "../config/constants";
const baseUrl = BE_API_BASE_URL;

// Fetch all brands
export async function getBrandById(id) {
  const accessToken = auth.currentUser.accessToken;
  try {
    const response = await fetch(`${baseUrl}/brands/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) throw new Error("Network response was not ok.");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching all brands:", error);
    return [];
  }
}

export async function getBrands(page = 1) {
  const accessToken = auth.currentUser.accessToken;
  try {
    const response = await fetch(
      `${baseUrl}/brands?page=${encodeURIComponent(page)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) throw new Error("Network response was not ok.");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching all brands:", error);
    return [];
  }
}

// Search brands by keyword
export async function searchBrands(keyword) {
  const accessToken = auth.currentUser.accessToken;
  try {
    const response = await fetch(
      `${baseUrl}/brands/search?keyword=${encodeURIComponent(keyword)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) throw new Error("Network response was not ok.");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error searching brands with keyword ${keyword}:`, error);
    return [];
  }
}

// Filter brands with additional criteria
export async function filterBrands(filters, page = 1, limit = 10) {
  const accessToken = auth.currentUser.accessToken;
  try {
    const queryParams = new URLSearchParams({ page, limit }).toString();
    const response = await fetch(`${baseUrl}/brands/filter?${queryParams}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(filters),
    });
    if (!response.ok) throw new Error("Network response was not ok.");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error filtering brands:", error);
    return { data: [], count: 0 };
  }
}

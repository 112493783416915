import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { SelectField, TextField } from "../../components/Fields";
import { Logo } from "../../components/Logo";
import { SlimLayout } from "../../components/SlimLayout";
import { displaySuccess } from "../../utils/notificationHandler";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase";
import { useAuth } from "../../hooks/auth";

export const metadata = {
  title: "Sign Up",
};

export default function Register() {
  const { register, signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralSource, setReferralSource] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        navigate("/brands", { replace: true });
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const registration = {
        email,
        password,
        firstName,
        lastName,
        referralSource,
      };
      const success = await register(registration);

      if (success) {
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SlimLayout>
      <div className="flex">
        <Logo className="h-10 w-auto" />
      </div>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Get started for free
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        Already registered?{" "}
        <a href="/login" className="font-medium text-blue-600 hover:underline">
          Sign in
        </a>{" "}
        to your account.
      </p>

      {/* Google Login */}
      <div>
        <div className="mt-10 grid grid-cols-1 gap-4">
          <Link
            onClick={(e) => {
              e.preventDefault();
              signInWithGoogle();
            }}
            className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
          >
            <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
              <path
                d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                fill="#EA4335"
              />
              <path
                d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                fill="#4285F4"
              />
              <path
                d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                fill="#FBBC05"
              />
              <path
                d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                fill="#34A853"
              />
            </svg>
            <span className="text-sm font-semibold leading-6">
              Continue with Google
            </span>
          </Link>
        </div>
        <div className="relative mt-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center text-sm font-medium leading-6">
            <span className="bg-white px-6 text-gray-900">Or</span>
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2"
      >
        <TextField
          label="First name"
          name="first_name"
          type="text"
          autoComplete="given-name"
          required
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          label="Last name"
          name="last_name"
          type="text"
          autoComplete="family-name"
          required
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          className="col-span-full"
          label="Email address"
          name="email"
          type="email"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className="col-span-full"
          label="Password"
          name="password"
          type="password"
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* <SelectField
          className="col-span-full"
          label="How did you hear about us?"
          name="referral_source"
          onChange={(e) => setReferralSource(e.target.value)}
        >
          <option>AltaVista search</option>
          <option>Super Bowl commercial</option>
          <option>Our route 34 city bus ad</option>
          <option>The “Never Use This” podcast</option>
        </SelectField> */}
        <div className="col-span-full">
          <Button type="submit" variant="solid" color="blue" className="w-full">
            Sign up →
          </Button>
        </div>
      </form>
    </SlimLayout>
  );
}

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// PROD
const firebaseConfig = {
  apiKey: "AIzaSyBHsKbh3uhZmeMumnejTdi67qZG3gvKYe4",
  authDomain: "trend-search-e15c8.firebaseapp.com",
  projectId: "trend-search-e15c8",
  storageBucket: "trend-search-e15c8.appspot.com",
  messagingSenderId: "612067595251",
  appId: "1:612067595251:web:18184726fde63d9aa9b886",
  measurementId: "G-5QTJQRGL7C",
};

// DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyCNAEVPov9EbuYa7DlvjqeAyGtvQAO3hck",
//   authDomain: "trendingstores-91529.firebaseapp.com",
//   projectId: "trendingstores-91529",
//   storageBucket: "trendingstores-91529.appspot.com",
//   messagingSenderId: "90905482162",
//   appId: "1:90905482162:web:0fa466bbc844fdf0750aab",
//   measurementId: "G-D65N5GDSSP",
// };

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);

import React, { useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

const EyeIconToggle = ({ id, hidden, onAction }) => {
  const [isHidden, setIsHidden] = useState(hidden);
  const [isHovered, setIsHovered] = useState(false); // Add hover state

  const handleToggle = () => {
    setIsHidden(!isHidden);
    onAction(id);
  };

  // use effect to log the isHidden var
  useEffect(() => {}, [isHidden]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div onClick={handleToggle} className="cursor-pointer">
      {isHidden ? (
        <EyeSlashIcon className="h-6 w-6 text-gray-300 hover:text-gray-400" />
      ) : (
        <EyeIcon className="h-6 w-6 text-gray-400 hover:text-gray-400" />
      )}
    </div>
  );
};

export default EyeIconToggle;

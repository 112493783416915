import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, currentUser } from "../../hooks/auth";
import { auth } from "../../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Logo } from "../../components/Logo";
import { Button } from "../../components/Button";
import { TextField } from "../../components/Fields";
import { Pricing } from "../../components/Pricing";
import { PlanHeader } from "./PlanHeader";
import BreadcrumbSteps from "./BreadcrumbSteps";
import { getCheckoutSession } from "../../api/billing";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // navigate("/brands", { replace: true });
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  const initiateCheckout = (tier, interval) => {
    getCheckoutSession(tier.priceId[interval.value], "/settings/plan");
  };

  return (
    <>
      <PlanHeader />
      <BreadcrumbSteps />
      <Pricing onAction={initiateCheckout} />
    </>
  );
}

import { Link } from "react-router-dom";
import { urlFormatter } from "../utils/urlFormatter";
import ProfileImage from "./ProfileImage";

export default function SearchResultBrand({ item, onAction }) {
  return (
    <div
      key={item.id}
      className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
    >
      <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
        <ProfileImage
          className="h-12 w-12 object-cover rounded-lg"
          item={item}
        />
      </div>
      <div>
        <Link
          to={`brands/${item.id}/overview`}
          className="font-semibold text-gray-900"
          onClick={() => {
            onAction();
          }}
        >
          {item.brand_name}
          <span className="absolute inset-0" />
        </Link>
        <p className="mt-1 text-gray-600">{urlFormatter(item.brand_url)}</p>
      </div>
    </div>
  );
}

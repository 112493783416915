import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useFilters } from "./FiltersContext";
import { categories } from "../../utils/categories"; // Update paths as needed
import { locations } from "../../utils/locations"; // Update paths as needed

import EyeIconToggle from "../../components/EyeIconToggle";
import ComboBoxLeftCheck from "../../components/ComboBoxLeftCheck";
import { Menu, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const sortOptions = [{ name: "Most Popular", href: "#", current: true }];

const FilterSection = () => {
  const {
    filtersAndInputs,
    setFiltersAndInputs,
    handleLocationSelection,
    handleCategorySelection,
    handleToggleColumn,
    handleSearchWithFilters,
  } = useFilters();

  const [open, setOpen] = useState(false);

  // Implement UI for selecting filters similar to the original implementation
  return (
    <div className="border-b border-gray-200 bg-white pb-4">
      <div className="mx-auto flex items-center justify-between px-4 sm:px-6 lg:px-8">
        <Menu as="div" className="relative inline-block text-left">
          {/* <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div> */}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {sortOptions.map((option) => (
                  <Menu.Item key={option.name}>
                    {({ active }) => (
                      <Link
                        to={option.href}
                        className={classNames(
                          option.current
                            ? "font-medium text-gray-900"
                            : "text-gray-500",
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        {option.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <button
          type="button"
          className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
          onClick={() => setOpen(true)}
        >
          Filters
        </button>

        <div className="hidden sm:block">
          <div className="flow-root">
            <Popover.Group className="mt-5 -mx-4 flex items-center divide-x divide-gray-200">
              {filtersAndInputs.map((filterSection) => {
                if (
                  filterSection.id === "brand" ||
                  filterSection.id === "description" ||
                  filterSection.id === "brand_url"
                ) {
                  return null;
                }
                return (
                  <Popover
                    key={filterSection.id}
                    className="relative inline-block px-4 text-left"
                  >
                    <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      <span>{filterSection.name}</span>
                      {/* {sectionIdx === 0 ? (
                       <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                         {section.options.filter((option) => option.checked).length}
                       </span>
                     ) : null} */}
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form className="space-y-4">
                          {filterSection.options.map((option, optionIdx) => (
                            <div
                              key={option.dataKey}
                              className="flex items-center"
                            >
                              {filterSection.id === "location" && (
                                <div className="flex items-center gap-x-4">
                                  <div className="min-w-[300px] items-center">
                                    <ComboBoxLeftCheck
                                      items={locations.countries}
                                      placeholder="Locations"
                                      onSelectionChange={
                                        handleLocationSelection
                                      }
                                    />
                                  </div>
                                  <div className="flex items-center mt-1">
                                    {option.hasColumn && (
                                      <EyeIconToggle
                                        className="h-5 w-1 text-gray-500"
                                        id={option.dataKey}
                                        hidden={option.columnHidden}
                                        onAction={handleToggleColumn}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}

                              {filterSection.id === "category" && (
                                <div className="flex items-center gap-x-4">
                                  <div className="min-w-[300px] items-center">
                                    <ComboBoxLeftCheck
                                      items={categories}
                                      placeholder="Categories"
                                      onSelectionChange={
                                        handleCategorySelection
                                      }
                                    />
                                  </div>
                                  <div className="flex items-center mt-1">
                                    {option.hasColumn && (
                                      <EyeIconToggle
                                        className="h-5 w-1 text-gray-500"
                                        id={option.dataKey}
                                        hidden={option.columnHidden}
                                        onAction={handleToggleColumn}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}

                              {/* Only render the input and label if sectionIdx is not 0 or 1 */}
                              {filterSection.id !== "location" &&
                                filterSection.id !== "category" &&
                                filterSection.id !== "other" && (
                                  <>
                                    <input
                                      id={`filter-${filterSection.id}-${optionIdx}`}
                                      name={`${filterSection.id}[]`}
                                      defaultValue={option.checked}
                                      type="checkbox"
                                      defaultChecked={option.checked}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      onChange={(e) => {
                                        option.checked = e.target.checked;
                                        if (option.isBool) {
                                          option.enabled = true;
                                        } else {
                                          option.minSet = option.min;
                                          option.maxSet = option.max;
                                        }
                                        setFiltersAndInputs([
                                          ...filtersAndInputs,
                                        ]);
                                      }}
                                    />
                                    <label
                                      htmlFor={`filter-${filterSection.id}-${optionIdx}`}
                                      className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 flex-grow"
                                    >
                                      {option.label}
                                    </label>

                                    {option.hasColumn && (
                                      <EyeIconToggle
                                        className="h-5 w-5 text-gray-500"
                                        id={option.dataKey}
                                        hidden={option.columnHidden}
                                        onAction={handleToggleColumn}
                                      />
                                    )}
                                  </>
                                )}

                              {/* Only render the input and label if sectionIdx is 0 or 1 */}
                              {filterSection.id === "other" && (
                                <>
                                  <input
                                    id={`filter-${filterSection.id}-${optionIdx}`}
                                    name={`${filterSection.id}[]`}
                                    defaultValue={option.checked}
                                    disabled={
                                      option.hasOwnProperty("checked")
                                        ? false
                                        : true
                                    }
                                    type="checkbox"
                                    defaultChecked={option.checked}
                                    className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 ${
                                      !option.hasOwnProperty("checked")
                                        ? "opacity-50 cursor-not-allowed border-gray-200 bg-gray-100"
                                        : ""
                                    }`}
                                    onChange={(e) => {
                                      option.checked = e.target.checked;
                                      if (option.isBool) {
                                        option.enabled = true;
                                      } else {
                                        option.minSet = option.min;
                                        option.maxSet = option.max;
                                      }
                                      setFiltersAndInputs([
                                        ...filtersAndInputs,
                                      ]);
                                    }}
                                  />
                                  <label
                                    htmlFor={`filter-${filterSection.id}-${optionIdx}`}
                                    className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 flex-grow"
                                  >
                                    {option.label}
                                  </label>

                                  {option.hasColumn && (
                                    <EyeIconToggle
                                      className="h-5 w-5 text-gray-500"
                                      id={option.dataKey}
                                      hidden={option.columnHidden}
                                      onAction={handleToggleColumn}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </form>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                );
              })}
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-10 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => handleSearchWithFilters()}
              >
                Search
              </button>
            </Popover.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;

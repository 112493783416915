import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CallToAction } from "./CallToAction";
import { Faqs } from "./Faqs";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Hero } from "./Hero";
import { Pricing } from "../../components/Pricing";
import { PrimaryFeatures } from "./PrimaryFeatures";
import { SecondaryFeatures } from "./SecondaryFeatures";
import { Testimonials } from "./Testimonials";

const SmoothScroll = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return null;
};

export default function LandingPage() {
  return (
    <>
      <Header />
      <SmoothScroll />
      <main>
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <Pricing />
        <Testimonials />
        <Faqs />
        <CallToAction />
      </main>
      <Footer />
    </>
  );
}

import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  ChatBubbleOvalLeftIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

import { NavLink, Link, Outlet } from "react-router-dom";

import { Logo } from "../../components/Logo";
import { useAuth } from "../../hooks/auth";
import { auth } from "../../config/firebase";
import SearchResultBrand from "../../components/SearchResultBrand";

const { searchBrands } = require("../../api/brands");

const navigation = [
  { name: "Browse", href: `brands`, icon: HomeIcon, current: false },
  {
    name: "Search",
    href: `brands/search`,
    icon: MagnifyingGlassIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout } = useAuth();

  const user = auth.currentUser;

  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  async function searchBrand(name) {
    if (name.length > 0) {
      const results = await searchBrands(name);
      setIsOpen(true);
      setSearchResults(results.data);
    } else {
      setIsOpen(false);
      setSearchResults([]);
    }
  }

  const handleBrandSelect = () => {
    setIsOpen(false);
    setSearchQuery("");
  };

  useEffect(() => {
    if (!user) throw new Error("No authenticated user found");

    const handler = setTimeout(() => {
      if (searchQuery.length > 0) {
        searchBrand(searchQuery);
      } else {
        setIsOpen(false);
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(handler);
  }, [searchQuery]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <Logo className="h-10 w-auto" link={"brands"} />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.href}
                                  onClick={() => setSidebarOpen(false)} // Add this line
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <NavLink
                            to={`/settings/profile`}
                            onClick={() => setSidebarOpen(false)} // Add this line
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            Settings
                          </NavLink>
                        </li>
                        <hr className="my-0 border-gray-200" /> {/* Divider */}
                        <button
                          onClick={logout}
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          style={{ maxWidth: "100%" }} // Ensure the button doesn't extend beyond its container
                        >
                          <UserCircleIcon
                            className="h-8 w-8 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Logout</span>
                          <div className="flex-grow min-w-0">
                            {" "}
                            {/* Container for the email, to apply ellipsis */}
                            <span
                              className="block overflow-hidden text-ellipsis whitespace-nowrap"
                              aria-hidden="true"
                            >
                              {user.displayName || user.email}
                            </span>
                          </div>
                          <ArrowRightOnRectangleIcon
                            className="h-5 w-7 text-gray-400"
                            aria-hidden="true"
                          />{" "}
                        </button>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <Logo className="h-10 w-auto" link={"brands"} />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-50 text-indigo-600"
                              : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-indigo-600"
                                : "text-gray-400 group-hover:text-indigo-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  {/* <NavLink
                    to={`/support`}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    <ChatBubbleOvalLeftIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                    Support
                  </NavLink> */}
                  <NavLink
                    to={`/settings/profile`}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                    Settings
                  </NavLink>
                  <hr className="my-4 border-gray-200" /> {/* Divider */}
                  <button
                    onClick={logout}
                    className="flex items-center gap-x-2 px-0 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                    style={{ maxWidth: "100%" }} // Ensure the button doesn't extend beyond its container
                  >
                    <UserCircleIcon
                      className="h-8 w-8 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Logout</span>
                    <div className="flex-grow min-w-0">
                      {" "}
                      {/* Container for the email, to apply ellipsis */}
                      <span
                        className="block overflow-hidden text-ellipsis whitespace-nowrap"
                        aria-hidden="true"
                      >
                        {user.displayName || user.email}
                      </span>
                    </div>
                    <ArrowRightOnRectangleIcon
                      className="h-5 w-7 text-gray-400"
                      aria-hidden="true"
                    />{" "}
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-200 lg:hidden"
              aria-hidden="true"
            />

            {/* Custom Popover */}
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <Popover className="relative flex flex-1">
                <div className="flex flex-1">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search brands..."
                    type="search"
                    name="search"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>

                <Transition
                  as={Fragment}
                  show={isOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 top-16 max-w-sm w-full bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    {searchResults.length > 0 && (
                      <div className="py-1">
                        {searchResults.map((result) => (
                          <SearchResultBrand
                            key={result.id}
                            item={result}
                            onAction={handleBrandSelect}
                          />
                        ))}
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>
          </div>

          <main>
            <div>
              {
                // children
                <Outlet />
              }
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
